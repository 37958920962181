import React, { Fragment, useContext } from 'react'
// import { Helmet } from 'react-helmet'
import image6Src from "../assets/Images/birmingham-airport-image.png"
import image5Src from "../assets/Images/hello birmingham.jpg"
import image3Src from "../assets/Images/birmingham-airport-image.png"

// import Navbar8 from '../components/navbar8'
import Hero17 from '../components/hero17'
import Features24 from '../components/features24'
import CTA26 from '../components/cta26'
import Features25 from '../components/features25'
import Steps2 from '../components/steps2'
import Testimonial17 from '../components/testimonial17'
import Contact10 from '../components/contact10'
// import Footer4 from '../components/footer4'
import './home.css'
import LandingSection from '../components/LandingSection'
// import BenefitsSection from '../components/BenefitsSection'
import { FormSubmissionContext } from '../Context api/FormSubmissionContext';

const Home = (props) => {
  const { bookingAddress } = useContext(FormSubmissionContext);
  return (
    <div className="home-container">
      {/* <Helmet>
        <title>Bermingham Airport Taxi - Home</title>
        <meta property="og:title" content="Bermingham Airport Taxi" />
      </Helmet> */}


      <LandingSection />
      {/* <BenefitsSection /> */}

      <Hero17
        image3Src={image3Src}
        image5Src={image5Src}
        image6Src={image6Src}
        action2={
          <Fragment>
            <span className="home-text114 thq-body-small">
              Manage Booking
            </span>
          </Fragment>
        }
        action1={
          <Fragment>
            <span className="home-text115 thq-body-small">Book Now</span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <h1 className="home-text116 thq-heading-1">
              Book Your Birmingham Airport Taxi Online
            </h1>
          </Fragment>
        }
        content1={
          <Fragment>
            <p className="home-text117 thq-body-large">
              Get a hassle-free taxi booking experience with our easy-to-use
              online platform. Simply enter your pickup and drop-off locations,
              choose your preferred car type, and make your reservation in just
              a few clicks.
            </p>
          </Fragment>
        }
      ></Hero17>

      <Features24
        feature3Description={
          <Fragment>
            <span className="home-text118 thq-body-small">
              Safe and secure online platform to provide your information and
              make bookings with peace of mind
            </span>
          </Fragment>
        }
        feature3Title={
          <Fragment>
            <h2 className="home-text119 thq-heading-2">
              Secure Booking System
            </h2>
          </Fragment>
        }
        feature2Description={
          <Fragment>
            <span className="home-text120 thq-body-small">
              Choose from a selection of 3 types of cars to suit your needs and
              preferences
            </span>
          </Fragment>
        }
        feature1Title={
          <Fragment>
            <h2 className="home-text121 thq-heading-2">Easy Booking Process</h2>
          </Fragment>
        }
        feature1Description={
          <Fragment>
            <span className="home-text122 thq-body-small">
              Simple and user-friendly interface to book Birmingham airport
              taxis in just a few clicks
            </span>
          </Fragment>
        }
        feature2Title={
          <Fragment>
            <h2 className="home-text123 thq-heading-2">Multiple Car Options</h2>
          </Fragment>
        }
      ></Features24>

      <CTA26
        heading1={
          <Fragment>
            <span className="home-text124 thq-heading-2">
              Book Your Birmingham Airport Taxi Now!
            </span>
          </Fragment>
        }
        content1={
          <Fragment>
            <p className="home-text125 thq-body-large">
              Enter your pickup and drop-off addresses to get started.
            </p>
          </Fragment>
        }
        action1={
          <Fragment>
            <span className="home-text126">Get Taxi Quote</span>
          </Fragment>
        }
      ></CTA26>

      <Features25
        feature3Description={
          <Fragment>
            <span className="home-text127 thq-body-small">
              Safe and secure online platform to provide your information and
              make bookings with peace of mind
            </span>
          </Fragment>
        }
        feature1Description={
          <Fragment>
            <span className="home-text128 thq-body-small">
              Simple and user-friendly interface to book Birmingham airport
              taxis in just a few clicks
            </span>
          </Fragment>
        }
        feature2Title={
          <Fragment>
            <h2 className="home-text129 thq-heading-2">Multiple Car Options</h2>
          </Fragment>
        }
        feature1Title={
          <Fragment>
            <h2 className="home-text130 thq-heading-2">Easy Booking Process</h2>
          </Fragment>
        }
        feature2Description={
          <Fragment>
            <span className="home-text131 thq-body-small">
              Choose from a selection of 3 types of cars to suit your needs and
              preferences
            </span>
          </Fragment>
        }
        feature3Title={
          <Fragment>
            <h2 className="home-text132 thq-heading-2">
              Secure Booking System
            </h2>
          </Fragment>
        }
      ></Features25>

      <Steps2
        step1Description={
          <Fragment>
            <span className="home-text186 thq-body-small">
              Enter your pickup and drop-off addresses to get started with your
              booking.
            </span>
          </Fragment>
        }
        step3Description={
          <Fragment>
            <span className="home-text187 thq-body-small">
              Select from a range of car options including standard, executive,
              or luxury vehicles.
            </span>
          </Fragment>
        }
        step2Title={
          <Fragment>
            <h2 className="home-text188 thq-heading-2">Get Taxi Quotes</h2>
          </Fragment>
        }
        step2Description={
          <Fragment>
            <span className="home-text189 thq-body-small">
              Receive instant quotes for your journey based on the pickup and
              drop-off locations provided.
            </span>
          </Fragment>
        }
        step1Title={
          <Fragment>
            <h2 className="home-text190 thq-heading-2">
              Select Pickup and Drop-off Addresses
            </h2>
          </Fragment>
        }
        step3Title={
          <Fragment>
            <h2 className="home-text191 thq-heading-2">Choose Car Type</h2>
          </Fragment>
        }
        step4Description={
          <Fragment>
            <span className="home-text192 thq-body-small">
              Enter your contact details and any special requirements for your
              trip before confirming your booking.
            </span>
          </Fragment>
        }
        step4Title={
          <Fragment>
            <h2 className="home-text193 thq-heading-2">
              Provide User Information
            </h2>
          </Fragment>
        }
      ></Steps2>

      <Testimonial17
        author2Position={
          <Fragment>
            <span className="home-text194 thq-body-small">
            Marketing Manager, Skyline Enterprises
            </span>
          </Fragment>
        }
        author1Position={
          <Fragment>
            <span className="home-text195 thq-body-small">
            Global Ventures Ltd.
            </span>
          </Fragment>
        }
        author1Name={
          <Fragment>
            <strong className="home-text196 thq-body-large">John Smith</strong>
          </Fragment>
        }
        author3Name={
          <Fragment>
            <strong className="home-text197 thq-body-large">David Brown</strong>
          </Fragment>
        }
        review2={
          <Fragment>
            <span className="home-text198 thq-body-small">
              Booking a taxi to Birmingham airport has never been easier! The
              website is user-friendly, and I always get a prompt response with
              a fair quote. Highly recommended!
            </span>
          </Fragment>
        }
        author2Name={
          <Fragment>
            <strong className="home-text199 thq-body-large">
              Sarah Johnson
            </strong>
          </Fragment>
        }
        author4Position={
          <Fragment>
            <span className="home-text200 thq-body-small">Student</span>
          </Fragment>
        }
        author4Name={
          <Fragment>
            <strong className="home-text201 thq-body-large">Emily White</strong>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="home-text202 thq-body-small">
              I have been using Birmingham airport taxis for all my business
              trips, and I must say the service is exceptional. The drivers are
              professional, the cars are clean and comfortable, and the booking
              process is seamless.
            </span>
          </Fragment>
        }
        author3Position={
          <Fragment>
            <span className="home-text203 thq-body-small">Freelancer</span>
          </Fragment>
        }
        review1={
          <Fragment>
            <span className="home-text204 thq-body-small">
            The Birmingham Airport Taxi service has been a game-changer for our business travels. Their punctuality, professionalism, and seamless booking process ensure our team arrives on time and stress-free. Highly recommended!
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <h2 className="home-text205 thq-heading-2">Testimonials</h2>
          </Fragment>
        }
        review3={
          <Fragment>
            <span className="home-text206 thq-body-small">
              I rely on Birmingham airport taxis for all my travel needs. The
              drivers are courteous, the cars are well-maintained, and I
              appreciate the punctuality. Great service overall!
            </span>
          </Fragment>
        }
        review4={
          <Fragment>
            <span className="home-text207 thq-body-small">
              As a student, budget-friendly options are crucial for me.
              Birmingham airport taxis offer competitive prices without
              compromising on quality. I feel safe and comfortable every time I
              ride with them.
            </span>
          </Fragment>
        }
      ></Testimonial17>

      <Contact10
        content1={
          <Fragment>
            <p className="home-text208 thq-body-large">
            Need a ride? Reach out to us for seamless pickups and drop-offs at Birmingham Airport. We’re just a call away!
            </p>
          </Fragment>
        }
        location1Description={
          <Fragment>
            <p className="home-text209 thq-body-large">
              Visit our office for any inquiries or assistance.
            </p>
          </Fragment>
        }
        heading1={
          <Fragment>
            <h2 className="home-text210 thq-heading-2">Contact Us</h2>
          </Fragment>
        }
        location2Description={
          <Fragment>
            <p className="home-text211 thq-body-large">
              Call us for immediate support or booking questions.
            </p>
          </Fragment>
        }
        location1={
          <Fragment>
            <h3 className="home-text212 thq-heading-3">
              Address: {bookingAddress}
            </h3>
          </Fragment>
        }
        location2={
          <Fragment>
            <h3 className="home-text213 thq-heading-3">
              Phone: +44 7440 445863
            </h3>
          </Fragment>
        }
      ></Contact10>
    </div>
  )
}

export default Home
