import React from 'react';
import { motion } from 'framer-motion';
import "./services.css";
import icon1 from "../assets/Images/services-icon1.svg"

const servicesData = [
    {
        icon: icon1,
        title: 'Free Cancellation',
        description: 'Up to 24 hours before pickup',
    },
    {
        icon: 'https://img.icons8.com/ios-filled/64/ff8000/airport.png',
        title: 'Flight Tracking',
        description: 'Driver will monitor your flight',
    },
    {
        icon: 'https://img.icons8.com/ios-filled/64/ff8000/driver.png',
        title: 'Licensed Chauffeurs',
        description: 'Ensuring maximum comfort and safety',
    },
    {
        icon: 'https://img.icons8.com/ios-filled/64/ff8000/customer-support.png',
        title: '24/7 Support',
        description: 'Dedicated customer service',
    },
];

const Services = () => {
    return (
        <div className="container my-3">
            <div className="row gy-4">
                {servicesData.map((service, index) => (
                    <div className="col-6 col-md-6 col-lg-3" key={index}>
                        <motion.div
                            className="service-box"
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: index * 0.2 }}
                        >
                            <div className="service-icon">
                                <img src={service.icon} alt={service.title} />
                            </div>
                            <div>
                                <div className="service-title">{service.title}</div>
                                <div className="service-description text-muted">{service.description}</div>
                            </div>
                        </motion.div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Services;