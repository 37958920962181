import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import './features25.css'

const Features25 = ({
  feature4Title,
  feature4Description,
  feature4ImgSrc = 'https://cdn-images.fleetnews.co.uk/thumbs/960x960/web-clean/1/root/man-driving-stock-image.jpg',
  feature4ImgAlt = 'Image depicting experienced drivers',

  feature5Title,
  feature5Description,
  feature5ImgSrc = 'https://static.vecteezy.com/system/resources/previews/035/635/037/non_2x/urban-cityscape-with-taxi-cab-hand-with-smartphone-and-taxi-service-application-illustration-in-flat-style-vector.jpg',
  feature5ImgAlt = 'Image representing 24/7 availability',

  feature6Title,
  feature6Description,
  feature6ImgSrc = 'https://plus.unsplash.com/premium_photo-1661288476987-8ed1d2085133?q=80&w=720&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  feature6ImgAlt = 'Image showing comfortable rides',
}) => {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <div className="thq-section-padding p-4 container">
      <div className="features25-container2 thq-section-max-width">
        <div className="features25-tabs-menu">
          <div
            onClick={() => setActiveTab(0)}
            className="features25-tab-horizontal1"
          >
            <div className="features25-divider-container1">
              {activeTab === 0 && <div className="features25-container3"></div>}
            </div>
            <div className="features25-content1">
              {feature4Title ?? (
                <Fragment>
                  <h2 className="features25-text4 thq-heading-2">
                    Experienced Drivers
                  </h2>
                </Fragment>
              )}
              <span>
                {feature4Description ?? ( 
                  <Fragment>
                    <span className="features25-text2 thq-body-small">
                      Our drivers are highly experienced and provide a professional service ensuring your comfort and safety.
                    </span>
                  </Fragment>
                )}
              </span>
            </div>
          </div>
          <div
            onClick={() => setActiveTab(1)}
            className="features25-tab-horizontal2"
          >
            <div className="features25-divider-container2">
              {activeTab === 1 && <div className="features25-container4"></div>}
            </div>
            <div className="features25-content2">
              {feature5Title ?? (
                <Fragment>
                  <h2 className="features25-text3 thq-heading-2">
                    24/7 Availability
                  </h2>
                </Fragment>
              )}
              <span>
                {feature5Description ?? (
                  <Fragment>
                    <span className="features25-text5 thq-body-small">
                      We are available around the clock to ensure you can book a taxi anytime, anywhere.
                    </span>
                  </Fragment>
                )}
              </span>
            </div>
          </div>
          <div
            onClick={() => setActiveTab(2)}
            className="features25-tab-horizontal3"
          >
            <div className="features25-divider-container3">
              {activeTab === 2 && <div className="features25-container5"></div>}
            </div>
            <div className="features25-content3">
              {feature6Title ?? (
                <Fragment>
                  <h2 className="features25-text6 thq-heading-2">
                    Comfortable Rides
                  </h2>
                </Fragment>
              )}
              <span>
                {feature6Description ?? (
                  <Fragment>
                    <span className="features25-text1 thq-body-small">
                      Enjoy a comfortable and relaxing ride in our well-maintained vehicles, ensuring a pleasant journey.
                    </span>
                  </Fragment>
                )}
              </span>
            </div>
          </div>
        </div>
        <div className="features25-image-container">
          {activeTab === 0 && (
            <img
              alt={feature4ImgAlt}
              src={feature4ImgSrc}
              className="features25-image1 thq-img-ratio-16-9"
            />
          )}
          {activeTab === 1 && (
            <img
              alt={feature5ImgAlt}
              src={feature5ImgSrc}
              className="features25-image2 thq-img-ratio-16-9"
            />
          )}
          {activeTab === 2 && (
            <img
              alt={feature6ImgAlt}
              src={feature6ImgSrc}
              className="features25-image3 thq-img-ratio-16-9"
            />
          )}
        </div>
      </div>
    </div>
  )
}

Features25.propTypes = {
  feature4Title: PropTypes.element,
  feature4Description: PropTypes.element,
  feature4ImgSrc: PropTypes.string,
  feature4ImgAlt: PropTypes.string,
  feature5Title: PropTypes.element,
  feature5Description: PropTypes.element,
  feature5ImgSrc: PropTypes.string,
  feature5ImgAlt: PropTypes.string,
  feature6Title: PropTypes.element,
  feature6Description: PropTypes.element,
  feature6ImgSrc: PropTypes.string,
  feature6ImgAlt: PropTypes.string,
}

export default Features25
