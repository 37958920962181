import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

function MobileNo({ orderData, setOrderData, handleChange }) {
    return (
        <div className="phone-input-container">
            <div className="input-group">
                <PhoneInput
                    country={'gb'} // Default country code set to UK
                    // value={orderData.mobileNumber}
                    onChange={(phone) => handleChange({ target: { name: 'mobileNumber', value: phone } })}
                    containerStyle={{
                        width: '100%',
                        margin: '0 auto',
                        backgroundColor: '#f9f9f9',
                        borderRadius: '0.5rem',
                        // boxShadow: '0 0.25rem 0.375rem rgba(0, 0, 0, 0.1)',
                    }}
                    inputStyle={{
                        width: '100%',
                        height: '2.8125rem', // 45px
                        borderRadius: '0.1rem',
                        paddingLeft: '4.0625rem', // 65px
                        fontSize: '1rem', // 16px
                        border: '0.0625rem solid #ddd', // 1px
                    }}
                    buttonStyle={{
                        borderRadius: '0.5rem 0 0 0.5rem',
                        border: '0.0625rem solid #ddd', // 1px
                        backgroundColor: '#fff',
                        padding: '0.625rem', // 10px
                    }}
                    dropdownStyle={{
                        borderRadius: '0.5rem',
                        boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.1)',
                    }}
                    enableSearch
                    disableCountryCode={false}
                    required // Make the input required
                />
                <div className="invalid-feedback">Please enter a valid mobile number.</div>
            </div>
        </div>
    );
}

export default MobileNo;