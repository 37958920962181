import './suppressWarnings';
import "./components/Styles/default-style-sheet.css"
import "./components/Styles/reset-style-sheet.css"
import React, { Fragment, useContext, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { FetchProvider } from "./Context api/FetchContext";
import { FormSubmissionContext, FormSubmissionProvider } from './Context api/FormSubmissionContext';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, Link } from "react-router-dom";

import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css';
import 'animate.css';
import StructuredData from './StructuredData';

import './style.css';
import Navbar8 from './components/navbar8';
import Home from './views/home';
import Quotation from './views/Quotation';
import NotFound from './views/not-found';
import BookingForm from './views/BookingForm';
import PrivateRoute from './views/PrivateRoute';
import Footer from './components/Footer';
import Success from './components/Success';
import Cancel from './components/Cancel';
import ConfirmationPage from './components/ConfirmationPage';
import AboutUs from './components/AboutUs';
import FAQ from './components/FAQ';
import ContactUs from './components/ContactUs';
import OurVehicles from './components/OurVehicles';
import WhyBookSection from './components/WhyBookSection';
import TC from './views/T&C';

const App = () => {
  const location = useLocation();
  const { setIsFormSubmitted } = useContext(FormSubmissionContext);

  useEffect(() => {
    if (location.pathname === '/') {
      setIsFormSubmitted(false);
      sessionStorage.removeItem('fareDetails');
      sessionStorage.removeItem('orderData');
    }
  }, [location.pathname === '/', setIsFormSubmitted]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <Navbar8
        link1={
          <Fragment>
            <Link to="/">
              <span className="home-text104 thq-link thq-body-small">
                Home
              </span>
            </Link>
          </Fragment >
        }
        link6={
          <Fragment>
            <Link to="/t&c">
              <span className="home-text102 thq-link thq-body-small">T&C</span>
            </Link>
          </Fragment >
        }
        link2={
          <Fragment>
            <Link to="/faq">
              <span className="home-text102 thq-link thq-body-small">FAQ</span>
            </Link>
          </Fragment >
        }
        link3={
          <Fragment>
            <Link to="/about">
              <span className="home-text110 thq-link thq-body-small">
                About Us
              </span>
            </Link>
          </Fragment >
        }
        link5={
          <Fragment>
            <Link to="/contact">
              <span className="home-text110 thq-link thq-body-small">
                Contact Us
              </span>
            </Link>
          </Fragment >
        }
        link4={
          <Fragment>
            <Link to="/">
              <span className="home-text110 thq-link thq-body-small">
                Airport Service
              </span>
            </Link>
          </Fragment >
        }
        action1={
          <Fragment>
            <span className="home-text101">Book Now</span>
          </Fragment >
        }
        action2={
          <Fragment>
            <span className="home-text113">Manage Booking</span>
          </Fragment >
        }
      ></Navbar8 >
      {/* <PaymentGetway /> */}
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/quote" element={<PrivateRoute><Quotation /></PrivateRoute>} />
        <Route exact path="/booking-form" element={<PrivateRoute><BookingForm /></PrivateRoute>} />
        <Route exact path="/confirm" element={<PrivateRoute><ConfirmationPage /></PrivateRoute>} />
        <Route exact path="/success" element={<PrivateRoute><Success /></PrivateRoute>} />
        <Route exact path="/cancel" element={<PrivateRoute><Cancel /></PrivateRoute>} />

        <Route exact path="/t&c" element={<TC />} />
        <Route exact path="/faq" element={<FAQ />} />
        <Route exact path="/about" element={<AboutUs />} />
        <Route exact path="/contact" element={<ContactUs />} />

        <Route path="*" element={<NotFound />} />
        <Route path="*" element={<Navigate to="*" />} />
      </Routes>

      <WhyBookSection />
      <OurVehicles />
      <Footer />
    </>
  );
};

const Root = () => (
  <Router>
    <FetchProvider>
      <FormSubmissionProvider>
        <StructuredData />
        <ReactNotifications />
        <App />
      </FormSubmissionProvider>
    </FetchProvider>
  </Router>
);

const container = document.getElementById('app');
const root = createRoot(container);
root.render(<Root />);