import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
// import { FetchContext } from '../Context api/FetchContext'; // Import FetchContext
// import 'bootstrap/dist/css/bootstrap.min.css';
import './success.css'; // Import custom CSS file

function Cancel() {
    return (
        <Container className="d-flex justify-content-center align-items-center h-100 bg-danger-light">
            <Row className="text-center justify-content-center">
                <Col md={8} lg={6}>
                    <div className="cancel-card p-5 rounded shadow-lg my-4">
                        <FontAwesomeIcon icon={faTimesCircle} size="3x" className="mb-4" />
                        <h1 className="display-4 mb-4">Payment Canceled</h1>
                        <p className="lead mb-4">Your payment was canceled. Please try again or contact support if you need assistance.</p>
                        <Button variant="outline-light" href="/" className="btn-lg">Back to Home</Button>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default Cancel;
