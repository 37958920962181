// StepperComponent.js
import React from 'react';
import Stepper from 'react-stepper-horizontal';
// import 'react-stepper-horizontal/lib/style.css'; // Optional for default styles

const StepperComponent = ({ stepNo = 0 }) => {
    const steps = [
        { title: 'Locations' },
        { title: 'Vehicle' },
        { title: 'Details' },
        { title: 'Complete' }
    ];

    return (
        <div className="container">
            <div style={{ margin: '1.3rem' }}>
                <Stepper
                    steps={steps}
                    activeStep={stepNo} // Update this to manage the current active step
                    activeColor="var(--primaryColor)"
                    completeColor="var(--primaryColor)"
                    defaultColor="#ccc"
                    size={45}
                    circleFontSize={18}
                    circleTop={0}
                    lineMargin={10}
                />
            </div>
        </div>
    );
};

export default StepperComponent;