import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faMapMarkerAlt, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './footer.css';
import logo from "../assets/Images/logo.png"
import { FormSubmissionContext } from '../Context api/FormSubmissionContext';

const Footer = () => {
    const { bookingAddress } = useContext(FormSubmissionContext);
    return (
        <footer className="footer">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
                        <div className="d-flex align-items-center mb-3">
                            <img style={{backgroundColor: "#fff"}} src={logo} alt="Company Logo" className="logo me-2" />
                            <span className="h4 mb-0">Birmingham Airport Taxi</span>
                        </div>
                        <p className="text-light">
                            Providing reliable and efficient taxi services for airport transfers across the UK. Your comfort is our priority.
                        </p>
                        <div className="social-icons mt-3">
                            <a href="#" className="me-3 social-icon"><FontAwesomeIcon icon={faFacebook} /></a>
                            <a href="#" className="me-3 social-icon"><FontAwesomeIcon icon={faTwitter} /></a>
                            <a href="#" className="me-3 social-icon"><FontAwesomeIcon icon={faInstagram} /></a>
                            <a href="#" className="social-icon"><FontAwesomeIcon icon={faLinkedin} /></a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
                        <h5 className="text-uppercase fw-bold mb-3">Our Address</h5>
                        <ul className="list-unstyled">
                            <li className="mb-2">
                                <FontAwesomeIcon icon={faMapMarkerAlt} className="text-warning me-2" />
                                {bookingAddress}
                            </li>
                            <li className="mb-2">
                                <FontAwesomeIcon icon={faPhone} className="text-warning me-2" />
                                +44 7440 445863
                            </li>
                            <li>
                                <FontAwesomeIcon icon={faEnvelope} className="text-warning me-2" />
                                info@birminghamairporttaxicab.co.uk
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h5 className="text-uppercase fw-bold mb-3">Airport transfers</h5>
                        <ul className="list-unstyled">
                            <li className="mb-2"><a href="https://birminghamairporttaxicab.co.uk" className="text-decoration-none link">Heathrow Airport Transfers</a></li>
                            <li className="mb-2"><a href="https://birminghamairporttaxicab.co.uk" className="text-decoration-none link">Gatwick Airport Transfers</a></li>
                            <li className="mb-2"><a href="https://birminghamairporttaxicab.co.uk" className="text-decoration-none link">Stansted Airport Transfers</a></li>
                            <li className="mb-2"><a href="https://birminghamairporttaxicab.co.uk" className="text-decoration-none link">Luton Airport Transfers</a></li>
                            <li className="mb-2"><a href="https://birminghamairporttaxicab.co.uk" className="text-decoration-none link">Manchester Airport Transfers</a></li>
                            <li className="mb-2"><a href="https://birminghamairporttaxicab.co.uk" className="text-decoration-none link">Birmingham Airport Transfers</a></li>
                            <li><a href="https://birminghamairporttaxicab.co.uk" className="text-decoration-none link">London City Airport Transfers</a></li>
                        </ul>

                    </div>
                </div>
                <div className="text-center mt-4">
                    <p className="small text-light m-0">&copy; 2024 Birmingham Airport Taxi. All Rights Reserved. | Designed by <a className='text-light fw-bold text-decoration-none' href="https://sufyan.me" target='_blank'>https://sufyan.me</a></p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
