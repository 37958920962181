import React from 'react';
// import './AboutUs.css'; // Add this if you have specific styles
import "./sectionStyle.css"
import aboutImg1 from "../assets/Images/about1.jpg";
import aboutImg2 from "../assets/Images/about2.png";

const AboutUs = () => {
    return (
        <section className="aboutPage py-5">
            <div className="container">
                <div className="row gx-4 align-items-center justify-content-between">
                    <div className="col-md-6 order-2 order-md-1">
                        <div className="mt-5 mt-0 me-md-3 me-lg-5">
                            <span className="text-muted">Our Journey</span>
                            <h2 className="display-5 fw-bold">About Us</h2>
                            <p className="lead">
                                Birmingham Airport Taxi is committed to providing reliable, comfortable, and efficient transportation services across the UK. Our mission is to make your travel experience smooth, from the moment you book until you reach your destination.
                            </p>
                            <p className="lead">
                                We offer a modern fleet of well-maintained vehicles, operated by professional drivers dedicated to your safety and comfort. Whether you're traveling for business or leisure, we tailor our service to meet your specific needs, ensuring you arrive on time, every time.
                            </p>
                            <p className="lead">
                                With competitive pricing and a straightforward booking process, we aim to provide a transparent and trustworthy service. Experience the difference with Birmingham Airport Taxi, where your journey is our priority.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6 order-1 order-md-2">
                        <div className="row gx-2 gx-lg-3">
                            <div className="col-6">
                                <img
                                    className="img-fluid rounded-3"
                                    src={aboutImg1}
                                    alt="Our Fleet"
                                />
                            </div>
                            <div className="col-6">
                                <img
                                    className="img-fluid rounded-3"
                                    src={aboutImg2}
                                    alt="Professional Drivers"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutUs;
