import React from 'react';
import './ourVehicles.css'; // Assuming you have the CSS in this file
import car1 from "../assets/Images/saloon.webp";
import car2 from "../assets/Images/mpv.webp";
import car3 from "../assets/Images/miniVan.webp";

const vehicleData = [
    {
        img: car2,
        alt: "Standard MPV",
        title: "Standard MPV",
        desc: "Ideal for family trips with more luggage space.",
        price: "35.00"
    },
    {
        img: car1,
        alt: "Standard Saloon",
        title: "Standard Saloon",
        desc: "Efficient and comfortable, perfect for city rides.",
        price: "30.00"
    },
    {
        img: car3,
        alt: "Standard Mini Van",
        title: "Standard Mini Van",
        desc: "Spacious and comfortable for group travel.",
        price: "45.00"
    }
];

const OurVehicles = () => {
    return (
        <section className="vehicles-section">
            <div className="container">
                <h2>Our Vehicles</h2>
                <div className="row">
                    {vehicleData.map((vehicle, index) => (
                        <div className="col-md-4" key={index}>
                            <div className="vehicle-card">
                                <img src={vehicle.img} alt={vehicle.alt} />
                                <h3 className="vehicle-title">{vehicle.title}</h3>
                                <p className="vehicle-desc">{vehicle.desc}</p>
                                <p className="vehicle-price"><span>From</span> {`${vehicle.price} £`}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default OurVehicles;