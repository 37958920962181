import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import './whyBookSection.css'; // Import the custom CSS

const WhyBookSection = () => {
    return (
        <section className="why-book-section container">
            <div className="row">
                <div className="col-12 col-md-6">
                    <h5>Why Book Airport Taxi with us?</h5>
                    <ul className="benefit-list">
                        <li>
                            <FontAwesomeIcon icon={faCheckCircle} /> Up to 50% cheaper prices
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheckCircle} /> Guaranteed Pickups
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheckCircle} /> Transparent Pricing
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheckCircle} /> More affordable than black cabs
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheckCircle} /> No Hidden Costs
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheckCircle} /> No Charge for Flight Delays
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheckCircle} /> 30 Minutes Free Waiting Time
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheckCircle} /> Free Cancellations (T&C Apply)
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheckCircle} /> Real-Time Flight Monitoring
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faCheckCircle} /> 24/7 Customer Support
                        </li>
                    </ul>
                </div>
                <div className="col-12 col-md-6">
                    <table className="table table-striped table-bordered">
                        <tbody>
                            <tr>
                                <th>Prices</th>
                                <td>Up to 40% Cheaper</td>
                            </tr>
                            <tr>
                                <th>Cars Available</th>
                                <td>Saloon, Standard MPV, Mini Van</td>
                            </tr>
                            <tr>
                                <th>Drivers</th>
                                <td>Licensed & CRB Checked</td>
                            </tr>
                            <tr>
                                <th>Cancellation</th>
                                <td>No Charge *</td>
                            </tr>
                            <tr>
                                <th>Baby Seats</th>
                                <td>Available Free of Charge but subject to availability.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    );
};

export default WhyBookSection;