import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';

const FAQSection = () => {
    const faqs = [
        {
            question: 'What services does Birmingham Airport Taxi provide?',
            answer: 'We offer reliable and comfortable pickup and drop-off services to and from Birmingham Airport, catering to all your transportation needs within the UK.',
        },
        {
            question: 'How can I book a taxi?',
            answer: 'Booking a taxi is simple! You can book directly through our website by entering your pickup and drop-off locations, selecting your trip type, and confirming your booking.',
        },
        {
            question: 'What payment methods are accepted?',
            answer: 'We accept various payment methods, including credit/debit cards via our secure online payment gateway, powered by Stripe.',
        },
        {
            question: 'Can I make changes to my booking?',
            answer: 'Yes, you can easily modify or cancel your booking by contacting our customer support at least 24 hours before your scheduled pickup time.',
        },
        {
            question: 'Is there a limit on luggage?',
            answer: 'Our vehicles are spacious, but we recommend indicating the number of suitcases during booking to ensure we provide a vehicle that meets your needs.',
        },
        {
            question: 'What safety measures are in place during travel?',
            answer: 'We prioritize your safety. All our drivers are professionally trained, and our vehicles are sanitized regularly to ensure a safe journey.',
        },
        {
            question: 'How can I make a booking?',
            answer: 'You can make a booking through our online form which is very easy to use, simply enter your pickup and drop-off locations and preferred date of journey and click on the “quote” button, you will then be presented with a quote page with different vehicles. Choose a vehicle depending on your needs and enter your journey details in the booking form, including pickup time, passenger name, phone number, email address, flight number (if booking an airport transfer) and click book now button and choose to pay by card or cash. You can also book via our online chat and support phone number as well.',
        },
        {
            question: 'Can I make a booking over the phone?',
            answer: 'Yes! You can call us on 01582 801611 and one of our controlling staff will be able to make a booking for you.',
        },
        {
            question: 'Can I book a return journey?',
            answer: 'Yes! When making a booking, you will be offered the option to book your return journey. You can also add this later on even if you have booked one-way and then want to change your option later on.',
        },
        {
            question: 'How can I pay for my booking?',
            answer: 'AH Airport Taxi provides various methods of payment that include Visa Card, Master Card, American Express, PayPal, Cash to the driver and all other major debit cards.',
        },
        {
            question: 'What size of vehicle do I need?',
            answer: 'On the quotation page, every vehicle is listed with the capacity of passengers and luggage it can take. Please choose your vehicle wisely. If you are still unsure with the capacity of passengers and luggage, you can call our office or chat with us directly for advice. If there is a situation where you have less people and more luggage or more people and less luggage then please do not hesitate to contact our support team who will be able to help you to get the correct vehicle booked.',
        },
        {
            question: 'How do I receive a booking confirmation?',
            answer: 'You will receive a booking confirmation email on your email address that you provide. However, there maybe situations where you do not receive a email due to misspelling the email address. In this instance, please get in touch with our support team who can correct your email address and re-send you the confirmation. In some situations, the booking confirmation may end up in your spam/junk folder so double check in there.',
        },
        {
            question: 'How do I locate my driver at the airport?',
            answer: 'If you have booked your transfer with the additional meet and greet option, your driver will be inside the terminal waiting for you, holding a board with your name on it so you don’t need to worry about finding him. If you have made the transfer as normal then you will get a call or receive a text from the driver once your flight has landed informing you of which zone to meet once you are ready. There are pickup and drop-off zones at every airport.',
        },
        {
            question: 'Extra charges if flight is delayed or late arrival?',
            answer: 'For bookings where collecting from an airport, we at AH Airport Taxi allow all our passengers free 45 minutes from the time the flight lands, to meeting the driver. After this, waiting time will be charged at £20 per hour. We therefore advise passengers to consider immigration processing times and baggage arrival, and request a deferred pick up time. We also recommend passengers to inform us of any delays so we can try and pre-plan. There will not be any compensation if the passenger is ready early, before the scheduled pick-up time. We will also not refund any costs if passengers device to take another transport and not wait for their driver.',
        },
        {
            question: 'Do you monitor/track flights?',
            answer: 'Our Team at AH Airport Taxi monitor your flights and delays however only accommodate flight delays up to a maximum of 45 minutes. We will try our best to facilitate our customers that have been affected by flight delays however we cannot guarantee this due to the capacity at the specific time. In this instance we reserve the right to cancel your booking where we could not facilitate you due to the delayed flight and we cannot be held legally responsible for. If we do cancel your booking due to the flight delay, you will be entitled to a full booking refund. We will not be held responsible to pay any additional charges that you may have to incur to arrange alternative transport.',
        },
        {
            question: 'Can I get a free baby/child car seat?',
            answer: 'We provide a Free Child Seat if requested by the customer. We try our best to provide the child seat but we can not guarantee a child seat for suitability and availability for your journey. Usage of a baby/child seat is entirely the passenger’s decision and we cannot be held responsible or liable for their usage. Please do note that the current UK Law for Child car seats is different if the child is in a taxi. Children can travel without one in a taxi if they are seated on the rear seat: And wear an adult seat belt if they’re 3 years or older Without a seat belt if they’re under the age of 3',
        },
        {
            question: 'Is your Company licensed?',
            answer: 'Yes, we are fully licensed.',
        },
        {
            question: 'Which Cities do you cover?',
            answer: 'We at AH Airport Taxi provide Private Hire Taxi Services Nationwide.',
        },
        {
            question: 'Do you provide VIP Transfers?',
            answer: 'We at AH Airport Taxi proudly provide VIP Transfers with our fully trained drivers and executive vehicles. Please, get in touch for more information.',
        },
        {
            question: 'What hours do you operate?',
            answer: 'AH Airport Taxi operates 24/7 to fulfil the needs of our customers.',
        },
        {
            question: 'Can we accommodate more passengers/luggage than the described capacity?',
            answer: 'No, it is strictly prohibited in compliance with UK law.',
        },
        {
            question: 'Can I smoke in the car?',
            answer: 'No, this is also strictly prohibited in compliance with UK law.',
        },
        {
            question: 'What is Meet and Greet Service?',
            answer: 'We at AH Airport Taxi provide a Meet and Greet Service at an additional cost where the driver will meet you inside the airport terminal, holding up a board with your name. You can choose this option on the booking form. The driver will also assist you with carrying luggage. We provide this service at all Airports.',
        },
        {
            question: 'Can I make a booking on behalf of a third party?',
            answer: 'Yes, you can book on behalf of a third party but please make sure to provide us with the correct contact details for the passengers.',
        },
        {
            question: 'What is the minimum notice required for a new booking?',
            answer: 'This will vary depending on the location and the type of car that you are required. We would highly recommend booking at least 12 hours prior from your trip.',
        },
        {
            question: 'Am I able to take pets on my journey?',
            answer: 'Unfortunately, pets are not allowed on your journey.',
        },
        {
            question: 'Do I require a working mobile at the time of pick-up?',
            answer: 'Yes, in order for your journey to run smoothly you must ensure you have a working mobile so the driver can contact you and inform you where to meet.',
        },
        {
            question: 'Are there any hidden costs?',
            answer: 'No, there are not any hidden costs. All barrier, car park, toll, congestion fees are included in the final price.',
        },
    ];

    return (
        <section className="py-5">
            <div className="container">
                <div className="row justify-content-center text-center mb-3">
                    <div className="col-lg-8 col-xl-7">
                        <span className="text-muted">F.A.Q</span>
                        <h2 className="display-5 fw-bold">Frequently Asked Questions</h2>
                        <p className="lead">Here are some of the most common questions our customers ask. If you have any other questions, feel free to contact us!</p>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-10">
                        <div className="row g-4 g-md-5">
                            {faqs.map((faq, index) => (
                                <div className="col-lg-6" key={index}>
                                    <div className="d-flex">
                                        <div className="text-success me-4">
                                            <FontAwesomeIcon icon={faCircleQuestion} size="2x" />
                                        </div>
                                        <div>
                                            <h5 className="mb-2 mb-lg-4 fw-bold">{faq.question}</h5>
                                            <p>{faq.answer}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FAQSection;