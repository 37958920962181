// emailService.js
import emailjs from 'emailjs-com';

// Ensure these environment variables are set correctly in your .env file
const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
const publicID = process.env.REACT_APP_EMAILJS_PUBLIC_ID;

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
};

export const sendBookingEmails = async (data, setLoading) => {
    setLoading(true);
    // Parameters for the passenger email
    const passengerEmailParams = {
        to_email: data.bookingData.email,
        origin: data.bookingData.origin,
        destination: data.bookingData.destination,
        tripType: data.bookingData.tripType,
        distanceKm: data.bookingData.distanceKm,
        distanceMiles: data.bookingData.distanceMiles,
        duration: data.bookingData.duration,
        passengers: data.bookingData.passengers,
        luggage: data.bookingData.luggage,
        pickupDate: formatDate(data.bookingData.pickupDate),
        pickupTime: data.bookingData.pickupTime,
        returnPickupDate: data.bookingData.returnPickupDate === null ? 'N/A' : formatDate(data.bookingData.returnPickupDate),
        returnPickupTime: data.bookingData.returnPickupTime === "" ? 'N/A' : data.bookingData.returnPickupTime,
        fare: data.bookingData.fare,
        name: data.bookingData.name,
        email: data.bookingData.email,
        mobileNumber: data.bookingData.mobileNumber,
        postCode: data.bookingData.postCode,
        flightNo: data.bookingData.flightNo,
        address: data.bookingData.address,
        instructionsForDriver: data.bookingData.instructionsForDriver === "" ? 'N/A' : data.bookingData.instructionsForDriver,
        vehicleType: data.bookingData.vehicleType,
        paymentMethod: data.bookingData.paymentMethod,
        referenceNumber: data.referenceNumber,
    };

    // Parameters for the admin email
    const adminEmailParams = {
        ...passengerEmailParams,
        to_email: 'bookings@birminghamairporttaxicab.co.uk',
    };

    try {
        // Send email to passenger
        await emailjs.send(serviceID, templateID, { ...passengerEmailParams, to_email: data.bookingData.email }, publicID);

        // Send email to admin
        await emailjs.send(serviceID, templateID, adminEmailParams, publicID);
        setLoading(false);
    } catch (error) {
        setLoading(false);
        console.error('Failed to send emails', error);
    }
};