import React, { useEffect } from 'react';
import StepperComponent from '../components/StepperComponent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faUsers, faSuitcase, faVirusSlash, faBell } from '@fortawesome/free-solid-svg-icons';
import './quotation.css';
import car1 from "../assets/Images/saloon.webp"
import car2 from "../assets/Images/mpv.webp"
import car3 from "../assets/Images/miniVan.webp"
import Services from '../components/Services';
import RouteDetails from '../components/RouteDetails';
import { Link, useNavigate } from 'react-router-dom';
import { useFetchContext } from '../Context api/FetchContext';


const Quotation = () => {
    const { fareDetails, setOrderData } = useFetchContext();
    const navigate = useNavigate();

    if (!fareDetails) {
        return navigate("/");
    }

    function formatPrice(number) {
        // Remove digits after the decimal point
        let integerPart = parseInt(number);
        // Add commas for thousands, millions, etc.
        let formattedPrice = integerPart.toLocaleString();
        return formattedPrice;
    }

    const cards = [
        {
            id: 1,
            image: car1,
            title: 'Standard Saloon',
            price: fareDetails.fares.standardFare,
            passengers: 'Up to 4 Passengers',
            suitcases: '2 medium suitcases'
        },
        {
            id: 2,
            image: car2,
            title: 'Standard MPV',
            price: fareDetails.fares.firstClassFare,
            passengers: 'Up to 5 Passengers',
            suitcases: '3 medium suitcases'
        },
        {
            id: 3,
            image: car3,
            title: 'Standard Mini Van',
            price: fareDetails.fares.miniVanFare,
            passengers: 'Up to 8 Passengers',
            suitcases: '8 medium suitcases'
        },
    ];

    const handleClick = async (vehicleType, fare) => {
        await setOrderData((prevState) => ({
            ...prevState,
            vehicleType: vehicleType,
            // price: cards.find(card => card.title === vehicleType).price
            fare: parseInt(fare)
        }));
    }
    return (
        <>
            <section className="quotation-body">
                <Services />
                <StepperComponent stepNo={1} />
                <div className="container container-custom quotationPage">
                    <div className="row">
                        <RouteDetails />
                        <div className="col-md-8">
                            <div className="row">
                                {cards.map((card) => (
                                    <div key={card.id} className="col-md-6 car-info">
                                        <div className="imageContainer">
                                            <img src={card.image} alt={card.title} />
                                        </div>

                                        <div className="title-price-container my-2">
                                            <h3 className="car-title">{card.title}</h3>
                                            <p className="car-price">{`£${formatPrice(card.price)}`}</p>
                                        </div>
                                        <p className='m-0'><FontAwesomeIcon icon={faUsers} />{card.passengers}</p>
                                        <p className='m-0'><FontAwesomeIcon icon={faSuitcase} />{card.suitcases}</p>

                                        <ul className="mt-2 car-features">
                                            <li className='text-muted feature'><FontAwesomeIcon icon={faClock} />Free Waiting time</li>
                                            <li className='text-muted feature'><FontAwesomeIcon icon={faBell} />Door-to-door</li>
                                            <li className='safe'><FontAwesomeIcon icon={faVirusSlash} />Safe and secure travel</li>
                                        </ul>

                                        <Link to="/booking-form" onClick={() => handleClick(card.title, card.price)} className="book-now-btn">BOOK NOW</Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Quotation;
