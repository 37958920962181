import React, { useRef, useEffect } from 'react';
import "./bookingForm.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Services from '../components/Services';
import StepperComponent from '../components/StepperComponent';
import RouteDetails from '../components/RouteDetails';
import MobileNo from '../components/MobileNo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faHome, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { useFetchContext } from '../Context api/FetchContext';
import SummaryTable from '../components/SummaryTable';
// import { sendBookingEmails } from '../emailService';
import Loading from '../components/Loading';
import { faPlaneArrival } from '@fortawesome/free-solid-svg-icons/faPlaneArrival';

const BookingForm = () => {
  const { orderData, setOrderData, submitCashOrder, paymentGetway, fareDetails, handleNotification, loading } = useFetchContext();
  const formRef = useRef(null);

  useEffect(() => {
    if (fareDetails) {
      setOrderData((prevState) => ({
        ...prevState,
        origin: fareDetails.origin,
        destination: fareDetails.destination,
        distanceKm: parseInt(fareDetails.distanceKm),
        distanceMiles: parseInt(fareDetails.distanceMiles),
        duration: fareDetails.duration,
        passengers: fareDetails.passengers,
        tripType: fareDetails.tripType,
        luggage: fareDetails.luggage,
        pickupDate: fareDetails.pickupDate,
        pickupTime: fareDetails.pickupTime,
        returnPickupDate: fareDetails.returnPickupDate,
        returnPickupTime: fareDetails.returnPickupTime,
      }));
    }
  }, [fareDetails, setOrderData]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setOrderData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const checkMissingFields = () => {
    const requiredFields = ['name', 'email', 'mobileNumber', 'address', 'postCode', 'flightNo', 'paymentMethod'];
    for (let field of requiredFields) {
      if (!orderData[field]) {
        return field;
      }
    }
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = formRef.current;

    if (form.checkValidity() === false) {
      handleNotification("danger", "Error!", "Please provide all the required fields.");
      e.stopPropagation();
    } else {
      const missingField = checkMissingFields();
      if (missingField) {
        // alert(`Please fill out the ${missingField} field.`);
        handleNotification("danger", "Error!", `Please fill out the ${missingField} field.`);
        return;
      }

      if (orderData.paymentMethod === "Card") {
        await paymentGetway();
      } else if (orderData.paymentMethod === "Cash") {
        try {
          await submitCashOrder();
          await handleNotification("success", "Success!", "Order received! Please check your email for confirmation and further details.");
        } catch (error) {
          console.error("Error during submission: ", error);
        }
      }
    }
    form.classList.add('was-validated');
  }

  return (
    <>
      <section className="quotation-body">
        <Services />
        <StepperComponent stepNo={2} />
        <div className="container container-custom quotationPage">
          <div className="row">
            <RouteDetails />
            <div className="col-md-8">
              <div className="bookingForm">
                <div className="card">
                  <h5 className="text-center thq-heading-2">Book Your Ride</h5>
                  <form ref={formRef} id='bookingForm2' name="needs-validation" noValidate>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label className='mb-1 mt-3' htmlFor="name">Passenger Name<span className="text-danger"> *</span></label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text"><FontAwesomeIcon icon={faUser} /></span>
                          </div>
                          <input type="text" className="form-control" name='name' id="name" required onChange={handleChange} />
                          <div className="invalid-feedback">Please enter the passenger name.</div>
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        <label className='mb-1 mt-3' htmlFor="contactEmail">Contact Email<span className="text-danger"> *</span></label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text"><FontAwesomeIcon icon={faEnvelope} /></span>
                          </div>
                          <input type="email" className="form-control" id="contactEmail" name='email' required onChange={handleChange} />
                          <div className="invalid-feedback">Please enter a valid email address.</div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label className='mb-1 mt-3' htmlFor="mobileNumber">Mobile Number<span className="text-danger"> *</span></label>
                        <div className="input-group">
                          <MobileNo orderData={orderData} setOrderData={setOrderData} handleChange={handleChange} />
                          <div className="invalid-feedback">Please enter a valid mobile number.</div>
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        <label className='mb-1 mt-3' htmlFor="flightNo">Flight number<span className="text-danger"> *</span></label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text"><FontAwesomeIcon icon={faPlaneArrival} /></span>
                          </div>
                          <input type="text" className="form-control" id="flightNo" name='flightNo' placeholder="e.g. BA1234" required onChange={handleChange} />
                          <div className="invalid-feedback">Please enter the flight number.</div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label className='mb-1 mt-3' htmlFor="postCode">Post Code<span className="text-danger"> *</span></label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text"><FontAwesomeIcon icon={faMapMarkerAlt} /></span>
                          </div>
                          <input type="text" className="form-control" id="postCode" name='postCode' placeholder="e.g. SW1A 1AA" required onChange={handleChange} />
                          <div className="invalid-feedback">Please enter the post code.</div>
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        <label className='mb-1 mt-3' htmlFor="address">Address<span className="text-danger"> *</span></label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text"><FontAwesomeIcon icon={faHome} /></span>
                          </div>
                          <input type="text" className="form-control" id="address" name='address' required onChange={handleChange} />
                          <div className="invalid-feedback">Please enter the address.</div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className='mb-1 mt-3' htmlFor="instructions">Instructions for Driver (optional)</label>
                      <textarea className="form-control" id="instructions" name='instructionsForDriver' rows="3" placeholder='e.g. Call me when you arrive' onChange={handleChange}></textarea>
                    </div>
                    <SummaryTable />

                    <div className="form-group my-2">
                      <label className='mb-1'>Payment Method<span className="text-danger"> *</span></label>
                      <div className="radioBtn">
                        <div className="form-check">
                          <input className="form-check-input" type="radio" name="paymentMethod" id="paymentMethodCard" value="Card" required onChange={handleChange} />
                          <label className="form-check-label" htmlFor="paymentMethodCard">
                            Card
                          </label>
                        </div>
                        <div className="form-check">
                          <input className="form-check-input" type="radio" name="paymentMethod" id="paymentMethodCash" value="Cash" required onChange={handleChange} />
                          <label className="form-check-label" htmlFor="paymentMethodCash">
                            Cash
                          </label>
                          <div className="invalid-feedback">Please select a payment method.</div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group mt-4">
                      <button type="submit" className="btn btn-primary btn-block" style={{ width: '100%' }} onClick={handleSubmit}>
                        {loading ? (
                          <Loading color="white" size="30" />
                        ) : (
                          <>
                            Book Now
                          </>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BookingForm;