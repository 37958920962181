import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './steps2.css'

const Steps2 = ({
    step1Description = undefined,
    step3Description = undefined,
    step2Title = undefined,
    step2Description = undefined,
    step1Title = undefined,
    step3Title = undefined,
    step4Description = undefined,
    step4Title = undefined,
}) => {
  return (
    <div className="steps2-container1 thq-section-padding">
      <div className="steps2-max-width thq-section-max-width">
        <div className="steps2-container2 thq-grid-2">
          <div className="steps2-section-header">
            <h2 className="thq-heading-2">
              How to Use Birmingham Airport Taxi?
            </h2>
            <p className="thq-body-large">
              Experience a seamless and reliable taxi booking service for your travels in the UK. Follow these simple steps to book your ride with Birmingham Airport Taxi and enjoy a comfortable journey.
            </p>
            <div className="steps2-actions">
              <a href='/' className="thq-button-animated thq-button-filled steps2-button text-decoration-none">
                <span className="thq-body-small">Book Now</span>
              </a>
            </div>
          </div>
          <div className="steps2-container3">
            <div className="steps2-container4 thq-card">
              {step1Title ?? (
                <Fragment>
                  <h2 className="steps2-text29 thq-heading-2">
                    Select Pickup and Drop-off Addresses
                  </h2>
                </Fragment>
              )}
              <span>
                {step1Description ?? (
                  <Fragment>
                    <span className="steps2-text25 thq-body-small">
                      Enter your pickup and drop-off addresses to get started with your booking. We cover all UK locations.
                    </span>
                  </Fragment>
                )}
              </span>
              <label className="steps2-text15 thq-heading-3">01</label>
            </div>
            <div className="steps2-container5 thq-card">
              {step2Title ?? (
                <Fragment>
                  <h2 className="steps2-text27 thq-heading-2">
                    Get Instant Taxi Quotes
                  </h2>
                </Fragment>
              )}
              <span>
                {step2Description ?? (
                  <Fragment>
                    <span className="steps2-text28 thq-body-small">
                      Receive instant, competitive quotes for your journey based on the pickup and drop-off locations you provide.
                    </span>
                  </Fragment>
                )}
              </span>
              <label className="steps2-text18 thq-heading-3">02</label>
            </div>
            <div className="steps2-container6 thq-card">
              {step3Title ?? (
                <Fragment>
                  <h2 className="steps2-text30 thq-heading-2">
                    Choose Your Vehicle
                  </h2>
                </Fragment>
              )}
              <span>
                {step3Description ?? (
                  <Fragment>
                    <span className="steps2-text26 thq-body-small">
                      Select from a variety of vehicle options, including standard, executive, or luxury cars to suit your needs.
                    </span>
                  </Fragment>
                )}
              </span>
              <label className="steps2-text21 thq-heading-3">03</label>
            </div>
            <div className="steps2-container7 thq-card">
              {step4Title ?? (
                <Fragment>
                  <h2 className="steps2-text32 thq-heading-2">
                    Provide Your Details
                  </h2>
                </Fragment>
              )}
              <span>
                {step4Description ?? (
                  <Fragment>
                    <span className="steps2-text31 thq-body-small">
                      Enter your contact information and any special requests to finalize your booking.
                    </span>
                  </Fragment>
                )}
              </span>
              <label className="steps2-text24 thq-heading-3">04</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Steps2.propTypes = {
  step1Description: PropTypes.element,
  step3Description: PropTypes.element,
  step2Title: PropTypes.element,
  step2Description: PropTypes.element,
  step1Title: PropTypes.element,
  step3Title: PropTypes.element,
  step4Description: PropTypes.element,
  step4Title: PropTypes.element,
}

export default Steps2
