import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './testimonial17.css'

const Testimonial17 = ({
  author2Position = undefined,
  author1Position = undefined,
  author3Alt = 'Image of David Brown',
  author1Name = undefined,
  author1Src =
  'https://images.unsplash.com/photo-1632377082508-bb1d06669dd5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMzc4MTE4NHw&ixlib=rb-4.0.3&q=80&w=144',
  author3Name = undefined,
  review2 = undefined,
  author2Name = undefined,
  author4Position = undefined,
  author4Name = undefined,
  author4Src =
  'https://images.unsplash.com/photo-1474533410427-a23da4fd49d0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMzc4MTE4NHw&ixlib=rb-4.0.3&q=80&w=144',
  author1Alt = 'Image of John Smith',
  author2Src =
  'https://images.unsplash.com/photo-1460723237483-7a6dc9d0b212?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMzc4MTE4NXw&ixlib=rb-4.0.3&q=80&w=144',
  author3Src =
  'https://images.unsplash.com/photo-1533685635527-9132968f1af1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMzc4MTE4NXw&ixlib=rb-4.0.3&q=80&w=144',
  author2Alt = 'Image of Sarah Johnson',
  author4Alt = 'Image of Emily White',
  content1 = undefined,
  author3Position = undefined,
  review1 = undefined,
  heading1 = undefined,
  review3 = undefined,
  review4 = undefined,
}) => {
  return (
    <div className="thq-section-padding">
      <div className="testimonial17-max-width thq-section-max-width">
        <div className="testimonial17-container10">
          {/* <h2> */}
          {heading1 ?? (
            <Fragment>
              <h2 className="testimonial17-text35 thq-heading-2">
                Testimonials
              </h2>
            </Fragment>
          )}
          {/* </h2> */}
          <span>
            {content1 ?? (
              <Fragment>
                <span className="testimonial17-text32 thq-body-small">
                  I have been using Birmingham airport taxis for all my business
                  trips, and I must say the service is exceptional. The drivers
                  are professional, the cars are clean and comfortable, and the
                  booking process is seamless.
                </span>
              </Fragment>
            )}
          </span>
        </div>
        <div className="thq-grid-2">
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="thq-card testimonial17-card1"
              >
                <div className="testimonial17-container12">
                  <img
                    alt={author1Alt}
                    src={author1Src}
                    className="testimonial17-image1"
                  />
                  <div className="testimonial17-container13">
                    <strong>
                      {author1Name ?? (
                        <Fragment>
                          <strong className="testimonial17-text26 thq-body-large">
                            John Smith
                          </strong>
                        </Fragment>
                      )}
                    </strong>
                    <span>
                      {author1Position ?? (
                        <Fragment>
                          <span className="testimonial17-text25 thq-body-small">
                            CEO, Company ABC
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
                <span>
                  {review1 ?? (
                    <Fragment>
                      <span className="testimonial17-text34 thq-body-small">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse varius enim in eros elementum tristique.
                        Duis cursus, mi quis viverra ornare, eros dolor interdum
                        nulla.
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="thq-card testimonial17-card2"
              >
                <div className="testimonial17-container14">
                  <img
                    alt={author2Alt}
                    src={author2Src}
                    className="testimonial17-image2"
                  />
                  <div className="testimonial17-container15">
                    <strong>
                      {author2Name ?? (
                        <Fragment>
                          <strong className="testimonial17-text29 thq-body-large">
                            Sarah Johnson
                          </strong>
                        </Fragment>
                      )}
                    </strong>
                    <span>
                      {author2Position ?? (
                        <Fragment>
                          <span className="testimonial17-text24 thq-body-small">
                            Marketing Manager, Company XYZ
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
                <span>
                  {review2 ?? (
                    <Fragment>
                      <span className="testimonial17-text28 thq-body-small">
                        Booking a taxi to Birmingham airport has never been
                        easier! The website is user-friendly, and I always get a
                        prompt response with a fair quote. Highly recommended!
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="thq-card testimonial17-card3"
              >
                <div className="testimonial17-container16">
                  <img
                    alt={author3Alt}
                    src={author3Src}
                    className="testimonial17-image3"
                  />
                  <div className="testimonial17-container17">
                    <strong>
                      {author3Name ?? (
                        <Fragment>
                          <strong className="testimonial17-text27 thq-body-large">
                            David Brown
                          </strong>
                        </Fragment>
                      )}
                    </strong>
                    <span>
                      {author3Position ?? (
                        <Fragment>
                          <span className="testimonial17-text33 thq-body-small">
                            Freelancer
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
                <span>
                  {review3 ?? (
                    <Fragment>
                      <span className="testimonial17-text36 thq-body-small">
                        I rely on Birmingham airport taxis for all my travel
                        needs. The drivers are courteous, the cars are
                        well-maintained, and I appreciate the punctuality. Great
                        service overall!
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="thq-card testimonial17-card4"
              >
                <div className="testimonial17-container18">
                  <img
                    alt={author4Alt}
                    src={author4Src}
                    className="testimonial17-image4"
                  />
                  <div className="testimonial17-container19">
                    <strong>
                      {author4Name ?? (
                        <Fragment>
                          <strong className="testimonial17-text31 thq-body-large">
                            Emily White
                          </strong>
                        </Fragment>
                      )}
                    </strong>
                    <span>
                      {author4Position ?? (
                        <Fragment>
                          <span className="testimonial17-text30 thq-body-small">
                            Student
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
                <span>
                  {review4 ?? (
                    <Fragment>
                      <span className="testimonial17-text37 thq-body-small">
                        As a student, budget-friendly options are crucial for
                        me. Birmingham airport taxis offer competitive prices
                        without compromising on quality. I feel safe and
                        comfortable every time I ride with them.
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// Testimonial17.defaultProps = {
//   author2Position: undefined,
//   author1Position: undefined,
//   author3Alt: 'Image of David Brown',
//   author1Name: undefined,
//   author1Src:
//     'https://images.unsplash.com/photo-1632377082508-bb1d06669dd5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMzc4MTE4NHw&ixlib=rb-4.0.3&q=80&w=144',
//   author3Name: undefined,
//   review2: undefined,
//   author2Name: undefined,
//   author4Position: undefined,
//   author4Name: undefined,
//   author4Src:
//     'https://images.unsplash.com/photo-1474533410427-a23da4fd49d0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMzc4MTE4NHw&ixlib=rb-4.0.3&q=80&w=144',
//   author1Alt: 'Image of John Smith',
//   author2Src:
//     'https://images.unsplash.com/photo-1460723237483-7a6dc9d0b212?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMzc4MTE4NXw&ixlib=rb-4.0.3&q=80&w=144',
//   author3Src:
//     'https://images.unsplash.com/photo-1533685635527-9132968f1af1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMzc4MTE4NXw&ixlib=rb-4.0.3&q=80&w=144',
//   author2Alt: 'Image of Sarah Johnson',
//   author4Alt: 'Image of Emily White',
//   content1: undefined,
//   author3Position: undefined,
//   review1: undefined,
//   heading1: undefined,
//   review3: undefined,
//   review4: undefined,
// }

Testimonial17.propTypes = {
  author2Position: PropTypes.element,
  author1Position: PropTypes.element,
  author3Alt: PropTypes.string,
  author1Name: PropTypes.element,
  author1Src: PropTypes.string,
  author3Name: PropTypes.element,
  review2: PropTypes.element,
  author2Name: PropTypes.element,
  author4Position: PropTypes.element,
  author4Name: PropTypes.element,
  author4Src: PropTypes.string,
  author1Alt: PropTypes.string,
  author2Src: PropTypes.string,
  author3Src: PropTypes.string,
  author2Alt: PropTypes.string,
  author4Alt: PropTypes.string,
  content1: PropTypes.element,
  author3Position: PropTypes.element,
  review1: PropTypes.element,
  heading1: PropTypes.element,
  review3: PropTypes.element,
  review4: PropTypes.element,
}

export default Testimonial17
