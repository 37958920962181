import React from 'react';
import '../views/quotation.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faCalendarAlt, faClock, faRoad, faHourglassHalf, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useFetchContext } from '../Context api/FetchContext';
import PaymentAside from './PaymentAside';

const RouteDetails = () => {
    const { fareDetails } = useFetchContext();
    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate(-1);
    };

    if (!fareDetails) {
        return <div style={{ color: "red" }}>Please reload website...</div>
    }


    return (
        <aside className="col-md-4">
            <div className="aside-container">
                <div className="route-info">
                    <h3 className="title">Route</h3>
                    <label htmlFor="origin" className='text-muted fw-bold'><FontAwesomeIcon icon={faMapMarkerAlt} /> Origin:</label>
                    <p className="detail" id='origin'>{fareDetails.origin}</p>
                    <label htmlFor="destination" className='text-muted fw-bold'><FontAwesomeIcon icon={faMapMarkerAlt} /> Destination:</label>
                    <p className="detail" id='destination'>{fareDetails.destination}</p>
                    <h4 className="title mt-4">Collection Time</h4>
                    <p className="detail">
                        <FontAwesomeIcon icon={faCalendarAlt} /> {fareDetails.pickupDate} - <FontAwesomeIcon icon={faClock} /> {fareDetails.pickupTime}
                    </p>
                    <h4 className="title mt-4">Distance</h4>
                    <p className="detail">
                        <FontAwesomeIcon icon={faRoad} /> {parseInt(fareDetails.distanceMiles)} miles
                    </p>
                    <h4 className="title mt-4">Duration</h4>
                    <p className="detail">
                        <FontAwesomeIcon icon={faHourglassHalf} /> {fareDetails.duration}
                    </p>
                </div>
                <PaymentAside />

                <button className="back-btn" onClick={handleBackClick}>
                    <FontAwesomeIcon icon={faArrowLeft} /> Back
                </button>
            </div>
        </aside>
    );
};

export default RouteDetails;