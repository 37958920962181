import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { FormSubmissionContext } from '../Context api/FormSubmissionContext';

const PrivateRoute = ({ children }) => {
    const { isFormSubmitted } = useContext(FormSubmissionContext);

    return isFormSubmitted ? children : <Navigate to="/" />;
};

export default PrivateRoute;