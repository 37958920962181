import React, { useContext, useEffect, useRef, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './LandingSection.css'; // Create this CSS file and copy the styles into it.
import { useFetchContext } from '../Context api/FetchContext';
// import { useNavigate } from 'react-router-dom';
import { FormSubmissionContext } from '../Context api/FormSubmissionContext';
import Loading from './Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays, faClock, faLocationDot, faSuitcaseRolling, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import landingImg from '../assets/Images/landingImg.webp';

const LandingSection = () => {
    const { host, addressForm, setAddressForm, CalculateFare, loading } = useFetchContext();
    const { setIsFormSubmitted } = useContext(FormSubmissionContext);

    const [isReturnTrip, setIsReturnTrip] = useState(false);

    const handleTripTypeChange = (e) => {
        const tripType = e.target.value;
        setIsReturnTrip(tripType === 'return');
        setAddressForm((prevState) => ({
            ...prevState,
            tripType: tripType
        }));
    };

    const [pickupSuggestions, setPickupSuggestions] = useState([]);
    const [dropoffSuggestions, setDropoffSuggestions] = useState([]);
    const [showPickupSuggestions, setShowPickupSuggestions] = useState(false);
    const [showDropoffSuggestions, setShowDropoffSuggestions] = useState(false);
    const pickupRef = useRef(null);
    const dropoffRef = useRef(null);

    const fetchSuggestions = async (query, setSuggestions) => {
        if (query && query.length > 2) {
            try {
                const response = await fetch(`${host}/api/suggestions`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ query })
                });
                const data = await response.json();
                setSuggestions(data.items);
            } catch (error) {
                console.error('Error fetching suggestions:', error);
            }
        } else {
            setSuggestions([]);
        }
    };

    useEffect(() => {
        if (addressForm.origin) {
            fetchSuggestions(addressForm.origin, setPickupSuggestions);
        }
    }, [addressForm.origin]);

    useEffect(() => {
        if (addressForm.destination) {
            fetchSuggestions(addressForm.destination, setDropoffSuggestions);
        }
    }, [addressForm.destination]);

    const handleSuggestionClick = (address, field) => {
        setAddressForm((prevState) => ({
            ...prevState,
            [field]: address
        }));
        if (field === 'origin') {
            setPickupSuggestions([]);
        } else {
            setDropoffSuggestions([]);
        }
    };

    const handleClickOutside = (event) => {
        if (
            pickupRef.current &&
            !pickupRef.current.contains(event.target) &&
            dropoffRef.current &&
            !dropoffRef.current.contains(event.target)
        ) {
            setShowPickupSuggestions(false);
            setShowDropoffSuggestions(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const getCurrentDate = () => {
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const dd = String(today.getDate()).padStart(2, '0');
        return `${yyyy}-${mm}-${dd}`;
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setAddressForm((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = e.target;

        if (form.checkValidity() === false) {
            e.stopPropagation();
        } else {
            await CalculateFare();
            setIsFormSubmitted(true);
        }
        form.classList.add('was-validated');
    };

    return (
        <section className="landingSection" style={{ backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)), url(${landingImg})` }}>
            <div className="container py-5">
                <div className="overlay"></div>
                <div className="row">
                    <div className="col-lg-7 justify-content-center">
                        <div className="content">
                            <h1>We Have A 24/7 Pick Drop Service<br />We Are Here To Serve You With Our Service</h1>
                            <p>We always focus on customer comfort and always give them cost-friendly offers. Our main goal is that
                                passengers feel safe and sound with us and we always focus on time schedule.</p>
                        </div>
                    </div>
                    <div className="col-lg-5 d-flex justify-content-center px-0">
                        <div className="form-container">
                            <h3 className="mb-3 text-center">Book Your Ride</h3>

                            <form className="needs-validation" noValidate onSubmit={handleSubmit}>
                                <div className="mb-3 position-relative">
                                    <label htmlFor="origin" className="form-label"><FontAwesomeIcon icon={faLocationDot} /> Pick up Address</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name='origin'
                                        id='origin'
                                        placeholder="Search Pickup Address"
                                        value={addressForm.origin}
                                        onChange={(e) => {
                                            handleChange(e);
                                            setShowPickupSuggestions(true);
                                        }}
                                        required
                                    />
                                    <div className="invalid-feedback">
                                        Please provide a pickup address.
                                    </div>
                                    {showPickupSuggestions && pickupSuggestions.length > 0 && (
                                        <div className="suggestions-box">
                                            {pickupSuggestions.map((item) => (
                                                <div
                                                    key={item.id}
                                                    className="suggestion-item"
                                                    onClick={() => handleSuggestionClick(item.address.label, 'origin')}
                                                >
                                                    {item.address.label}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <div className="mb-3 position-relative">
                                    <label htmlFor="destination" className="form-label"><FontAwesomeIcon icon={faLocationDot} /> Drop off Address</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name='destination'
                                        id='destination'
                                        placeholder="Search Drop-off Address"
                                        value={addressForm.destination}
                                        onChange={(e) => {
                                            handleChange(e);
                                            setShowDropoffSuggestions(true);
                                        }}
                                        required
                                    />
                                    <div className="invalid-feedback">
                                        Please provide a drop-off address.
                                    </div>
                                    {showDropoffSuggestions && dropoffSuggestions.length > 0 && (
                                        <div className="suggestions-box">
                                            {dropoffSuggestions.map((item) => (
                                                <div
                                                    key={item.id}
                                                    className="suggestion-item"
                                                    onClick={() => handleSuggestionClick(item.address.label, 'destination')}
                                                >
                                                    {item.address.label}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>

                                {/* <div className="mb-3 trip-type-style">
                                    <label className="form-check-label me-3">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="tripType"
                                            value="one-way"
                                            defaultChecked
                                            onChange={handleTripTypeChange}
                                        /> One way
                                    </label>
                                    <label className="form-check-label">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="tripType"
                                            value="return"
                                            onChange={handleTripTypeChange}
                                        /> Return
                                    </label>
                                </div> */}
                                <div className="form-group col-12 col-md-6">
                                    {/* <label htmlFor="tripType" className="form-label">Trip Type</label> */}
                                    <select
                                        id="tripType"
                                        name='tripType'
                                        className="form-select trip-type-selector"
                                        value={addressForm.tripType}
                                        onChange={handleTripTypeChange}
                                    >
                                        <option value="one-way">One Way</option>
                                        <option value="return">Return</option>
                                    </select>
                                </div>

                                <div className="row mb-3">
                                    <div className="col-12 col-md-6 mb-1">
                                        <div className="form-group">
                                            <label htmlFor="pickupDate"><FontAwesomeIcon icon={faCalendarDays} /> Pickup Date</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                name='pickupDate'
                                                id="pickupDate"
                                                min={getCurrentDate()}
                                                value={addressForm.pickupDate}
                                                onChange={handleChange}
                                                required
                                            />
                                            <div className="invalid-feedback">
                                                Please provide a pickup date.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="pickupTime"><FontAwesomeIcon icon={faClock} /> Pickup Time</label>
                                            <input
                                                type="time"
                                                className="form-control"
                                                name="pickupTime"
                                                id="pickupTime"
                                                value={addressForm.pickupTime}
                                                onChange={handleChange}
                                                required
                                            />
                                            <div className="invalid-feedback">
                                                Please provide a pickup time.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {isReturnTrip && (
                                    <div className="row mb-3">
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="returnPickupDate"><FontAwesomeIcon icon={faCalendarDays} /> Return Pickup Date</label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    name='returnPickupDate'
                                                    id="returnPickupDate"
                                                    min={getCurrentDate()}
                                                    value={addressForm.returnPickupDate}
                                                    onChange={handleChange}
                                                    required={isReturnTrip}
                                                />
                                                <div className="invalid-feedback">
                                                    Please provide a return pickup date.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="returnPickupTime"><FontAwesomeIcon icon={faClock} /> Return Pickup Time</label>
                                                <input
                                                    type="time"
                                                    className="form-control"
                                                    name='returnPickupTime'
                                                    id="returnPickupTime"
                                                    value={addressForm.returnPickupTime}
                                                    onChange={handleChange}
                                                    required={isReturnTrip}
                                                />
                                                <div className="invalid-feedback">
                                                    Please provide a return pickup time.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className="row mb-3">
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="passengers"><FontAwesomeIcon icon={faUserGroup} /> Passengers</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                name='passengers'
                                                placeholder="Passengers"
                                                id='passengers'
                                                min="1"
                                                value={addressForm.passengers}
                                                onChange={handleChange}
                                                required
                                            />
                                            <div className="invalid-feedback">Please provide the number of passengers.</div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="suitcases"><FontAwesomeIcon icon={faSuitcaseRolling} /> Luggage</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                name='luggage'
                                                placeholder="Suitcases"
                                                id='suitcases'
                                                min="0"
                                                value={addressForm.luggage}
                                                onChange={handleChange}
                                                required
                                            />
                                            <div className="invalid-feedback">Please provide the number of suitcases.</div>
                                        </div>
                                    </div>
                                </div>

                                <button type="submit" className="btn btn-light">
                                    {loading ? (
                                        <Loading color="white" size="25" />
                                    ) : (
                                        <>
                                            Get Taxi Quote
                                        </>
                                    )}
                                </button>
                            </form>

                        </div>
                    </div>

                </div>

            </div>
        </section>
    );
};

export default LandingSection;