import React from 'react';

const StructuredData = () => {
    const jsonLdData = {
        "@context": "https://schema.org",
        "@type": "TaxiService",
        "name": "Birmingham Airport Taxi",
        "description": "Birmingham Airport Taxi provides reliable and comfortable pickup and drop-off services across the UK. We specialize in airport transfers, ensuring you reach your destination on time, every time.",
        "image": "https://birminghamairporttaxicab.co.uk/poster_img.jpg",
        "address": {
            "@type": "PostalAddress",
            "addressLocality": "Birmingham",
            "addressRegion": "West Midlands",
            "addressCountry": "UK"
        },
        "url": "https://birminghamairporttaxicab.co.uk",
        "telephone": "+447440445863",
        "openingHours": "Mo-Su 00:00-24:00",
        // "sameAs": [
        //     "https://www.facebook.com/BirminghamAirportTaxi",
        //     "https://www.instagram.com/BirminghamAirportTaxi"
        // ],
        "potentialAction": {
            "@type": "SearchAction",
            "target": "https://birminghamairporttaxicab.co.uk/search?query={search_term_string}",
            "query-input": "required name=search_term_string"
        }
    };

    return (
        <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLdData) }}
        />
    );
};

export default StructuredData;
