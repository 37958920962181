import React, { createContext, useContext, useState, useEffect } from 'react';
import { Store } from 'react-notifications-component';
import { useNavigate } from 'react-router-dom';
import { stripePromise } from './stripePromise';
import { sendBookingEmails } from '../emailService';
export const FetchContext = createContext();

export const FetchProvider = (props) => {
    // const host = 'http://localhost:5000';
    const host = 'https://backend.birminghamairporttaxicab.co.uk';

    const contactNo = '+447440445863';

    function getCurrentTimePlusTwoHours() {
        const now = new Date();
        now.setHours(now.getHours() + 2);
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
    }

    const getCurrentDate = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const [addressForm, setAddressForm] = useState({
        origin: "",
        destination: "",
        pickupDate: getCurrentDate(),
        pickupTime: getCurrentTimePlusTwoHours(),
        tripType: "oneWay",
        returnPickupDate: "",
        returnPickupTime: "",
        passengers: "1",
        luggage: "0"
    });

    const [loading, setLoading] = useState(false);

    // Initialize fareDetails from session storage if available
    const [fareDetails, setFareDetails] = useState(() => {
        const savedFareDetails = sessionStorage.getItem('fareDetails');
        return savedFareDetails ? JSON.parse(savedFareDetails) : null;
    });

    // Initialize orderData from session storage if available
    const [orderData, setOrderData] = useState(() => {
        const savedOrderData = sessionStorage.getItem('orderData');
        return savedOrderData ? JSON.parse(savedOrderData) : {
            origin: "",
            destination: "",
            distanceKm: 0,
            distanceMiles: 0,
            duration: "",
            passengers: "",
            luggage: "",
            pickupDate: "",
            pickupTime: "",
            returnPickupDate: "",
            returnPickupTime: "",
            tripType: "",
            fare: 0,
            name: "",
            email: "",
            mobileNumber: "",
            postCode: "",
            flightNo: "",
            address: "",
            instructionsForDriver: "",
            vehicleType: "",
            paymentMethod: "Card",
            referenceNumber: "BAT000000",
        };
    });

    const handleNotification = (type, title, message, duration = 3500) => {
        Store.addNotification({
            title: title,
            message: message,
            type: type,
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: duration,
                onScreen: true
            }
        });
    }

    const navigate = useNavigate();

    // POST Calculate Fare
    const CalculateFare = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${host}/api/calculate-fare`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(addressForm),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to calculate fare');
            }

            const data = await response.json();
            setFareDetails(data);

            navigate('/quote');
            setLoading(false);
            handleNotification("success", "Success!", "Route Data Calculate successfully.");

        } catch (error) {
            setLoading(false);
            console.error(error);
            console.error(error.message);
            handleNotification("danger", "Error!", "Please add correct Address.");
        }
    };

    // Save fareDetails to session storage whenever it changes
    useEffect(() => {
        if (fareDetails) {
            sessionStorage.setItem('fareDetails', JSON.stringify(fareDetails));
        }
    }, [fareDetails]);

    // Save orderData to session storage whenever it changes
    useEffect(() => {
        if (orderData) {
            sessionStorage.setItem('orderData', JSON.stringify(orderData));
        }
    }, [orderData, setOrderData]);

    // Handle online and offline events
    useEffect(() => {
        const handleOffline = () => {
            handleNotification("danger", "Internet Error", "You are offline. Please check your internet connection.", 7000);
        };
        window.addEventListener('offline', handleOffline);
        return () => {
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    const submitOrder = async () => {
        // console.log(orderData);
        try {
            setLoading(true);
            const response = await fetch(`${host}/api/passenger`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(orderData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to Submit order');
            }

            const data = await response.json();
            setOrderData((prevState) => ({
                ...prevState,
                referenceNumber: data.referenceNumber
            }));
            // console.log(data);

            handleNotification("warning", "Please wait!", "Please wait few second for confirmation email.");

            await sendBookingEmails(data, setLoading);

            setLoading(false);
            sessionStorage.setItem('orderSubmitted', 'true'); // Set the flag
        } catch (error) {
            setLoading(false);
            console.error(error);
            handleNotification("danger", "Error!", "Please add valid details.");
        }
    };


    // ___________________Payment Getway_______________________
    const paymentGetway = async () => {
        try {
            setLoading(true);
            sessionStorage.setItem('orderSubmitted', 'false'); // Set the flag   
            sessionStorage.setItem('orderData', JSON.stringify(orderData));

            const response = await fetch(`${host}/payment/create-checkout-session`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    amount: orderData.fare * 100,
                    currency: 'gbp',
                    title: 'Birmingham Airport Taxi Booking',
                    description: 'A premium taxi service for Birmingham Airport.',
                    email: orderData.email,
                }),
            });
            const session = await response.json();
            const stripe = await stripePromise;

            const { error } = await stripe.redirectToCheckout({ sessionId: session.id });

            if (error) {
                throw new Error(error.message);
            }

            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error(error);
            handleNotification("danger", "Error!", "Failed Payment process");
        }
    };

    useEffect(() => {
        const handleOrderProcessing = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            if (urlParams.get('payment_status') === 'success') {
                const savedOrderData = sessionStorage.getItem('orderData');
                const orderSubmitted = sessionStorage.getItem('orderSubmitted'); // Check the flag

                if (savedOrderData && orderSubmitted !== 'true') {
                    const parsedOrderData = JSON.parse(savedOrderData);
                    setOrderData(parsedOrderData);
                    await submitOrder();
                    handleNotification("success", "Success!", "Order received! Please check your email for confirmation and further details.", 7000);
                    console.log("Payment Done");
                }
            }
        };
        handleOrderProcessing();
    }, []);



    // __________________Cash Order_______________________
    const submitCashOrder = async () => {
        try {
            setLoading(true);
            const savedOrderData = sessionStorage.getItem('orderData');
            const parsedOrderData = JSON.parse(savedOrderData);
            setOrderData(parsedOrderData);

            await submitOrder();
            navigate("/confirm");

            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error(error);
            handleNotification("danger", "Error!", "Failed Payment process");
        }
    };

    return (
        <FetchContext.Provider value={{
            host,
            contactNo,
            handleNotification,
            loading,
            setLoading,

            addressForm,
            setAddressForm,
            CalculateFare,
            fareDetails,

            orderData,
            setOrderData,
            submitOrder,
            stripePromise,
            paymentGetway,

            submitCashOrder,
        }}>
            {props.children}
        </FetchContext.Provider>
    );
};

export const useFetchContext = () => useContext(FetchContext);
