import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './summaryTable.css'; // Custom CSS for additional styling
import { useFetchContext } from '../Context api/FetchContext';

const SummaryTable = () => {
    const { fareDetails, orderData } = useFetchContext();
    return (
        <div className="table-responsive mt-4">
            <table className="table table-bordered table-hover table-striped">
                <thead className="thead-dark">
                    <tr>
                        <th colSpan="2" className="text-center">Passenger Order Summary</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">Pickup from</th>
                        <td>{fareDetails.origin}</td>
                    </tr>
                    <tr>
                        <th scope="row">Dropoff to</th>
                        <td>{fareDetails.destination}</td>
                    </tr>
                    <tr>
                        <th scope="row">Pickup Date</th>
                        <td>{fareDetails.pickupDate}</td>
                    </tr>
                    <tr>
                        <th scope="row">Pickup Time</th>
                        <td>{fareDetails.pickupTime}</td>
                    </tr>
                    {fareDetails.returnPickupDate !== "" && fareDetails.returnPickupTime !== "" && (
                        <>
                            <tr>
                                <th scope="row">Return Pickup Date</th>
                                <td>{fareDetails.returnPickupDate}</td>
                            </tr>
                            <tr>
                                <th scope="row">Return Pickup Time</th>
                                <td>{fareDetails.returnPickupTime}</td>
                            </tr>
                        </>
                    )}
                    <tr>
                        <th scope="row">Vehicle</th>
                        <td>{orderData.vehicleType}</td>
                    </tr>
                    {/* <tr className="table-warning">
                        <th scope="row">Total</th>
                        <td className="font-weight-bold">£716</td>
                    </tr> */}
                </tbody>
                <tfoot>
                    <tr>
                        <th scope="row">Total:</th>
                        <td>{`£${orderData.fare}`}</td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};

export default SummaryTable;