import React, { useRef, useEffect, useState } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faBolt, faCheckCircle, faLock, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import './paymentAside.css';
import { Link } from 'react-router-dom';

const paymentAside = () => {
    const cardRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            { threshold: 0.5 }
        );

        if (cardRef.current) {
            observer.observe(cardRef.current);
        }

        return () => {
            if (cardRef.current) {
                observer.unobserve(cardRef.current);
            }
        };
    }, []);

    return (
        <div className={`aside-custom py-4 ${isVisible ? 'animate' : ''}`} style={{ maxWidth: '350px' }} ref={cardRef}>
            <div className="card-body">
                <div className="detail feature">
                    <FontAwesomeIcon icon={faUsers} /> <span>Over 100,000 passengers transported</span>
                </div>
                <div className="detail feature">
                    <FontAwesomeIcon icon={faBolt} /> <span>Instant confirmation</span>
                </div>
                <div className="detail feature">
                    <FontAwesomeIcon icon={faCheckCircle} /> <span>All-inclusive pricing</span>
                </div>
                <div className="detail feature">
                    <FontAwesomeIcon icon={faLock} /> <span>Secure Payment by credit card, debit card</span>
                </div>

                <div className="payment-methods d-flex justify-content-center mt-3">
                    <img src="https://www.svgrepo.com/show/452128/visa.svg" alt="VISA" />
                    <img src="https://www.svgrepo.com/show/452059/mastercard.svg" alt="Mastercard" />
                    <img src="https://www.svgrepo.com/show/266090/maestro.svg" alt="Maestro" />
                    <img src="https://www.svgrepo.com/show/508403/amex.svg" alt="American Express" />
                </div>

                <Link to='/contact' className="help-centre">
                    <FontAwesomeIcon icon={faCircleInfo} />
                    <div className="help">
                        <p className='m-0'>Help Centre</p>
                        <p className='m-0'>Frequently asked questions</p>
                    </div>
                </Link>
            </div>
        </div>
    );
};

export default paymentAside;
