import React, { Fragment } from 'react'
import Script from 'dangerous-html/react'
import PropTypes from 'prop-types'
import './hero17.css'

const Hero17 = ({
  image3Src = 'https://images.unsplash.com/photo-1569229569803-69384f5efa83?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMzc4MTE4NHw&ixlib=rb-4.0.3&q=80&w=720',
  image8Alt = 'Hero Image',
  image2Src = 'https://images.unsplash.com/photo-1501514367484-a5804478bf64?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMzc4MTE4NXw&ixlib=rb-4.0.3&q=80&w=720',
  image6Alt = 'Hero Image',
  image11Src = 'https://static.independent.co.uk/2022/05/10/10/iStock-1341934488.jpg?quality=75&width=1250&crop=3%3A2%2Csmart&auto=webp',
  image5Alt = 'Hero Image',
  image1Alt = 'Hero Image',
  image7Src = 'https://images.unsplash.com/photo-1654125360394-7183a25a3c86?fm=jpg&q=60&w=720&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTh8fGxvcyUyMGFuZ2VsZXMlMjBhaXJwb3J0fGVufDB8fDB8fHww',
  image7Alt = 'Hero Image',
  image12Alt = 'Hero Image',
  image2Alt = 'Hero Image',
  image6Src = 'https://images.unsplash.com/photo-1720361394304-703bfb23832f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMzc4MTE4Nnw&ixlib=rb-4.0.3&q=80&w=720',
  image12Src = 'https://media.istockphoto.com/id/1321199544/photo/woman-waiting-for-taxi-with-face-mask-and-travel-case.webp?b=1&s=612x612&w=0&k=20&c=3YNeyrRzv0q3S0oKzwsu1c41JMXEbiE0C_elTaa2-2E=',
  image3Alt = 'Hero Image',
  image9Src = 'https://eastkent-taxis.co.uk/wp-content/uploads/2023/09/airport-transportation.jpg',
  image11Alt = 'Hero Image',
  action2,
  action1,
  image8Src = 'https://media.istockphoto.com/id/2120760459/photo/happy-friends-traveling-by-car.webp?b=1&s=612x612&w=0&k=20&c=R4tKcNH5m1UXipn8eHjX_FM1QKfKIjao4mRVzSZWpzY=&ixlib=rb-4.0.3&q=80&w=720',
  image5Src = 'https://images.unsplash.com/photo-1578287011757-4fee0839a449?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMzc4MTE4OHw&ixlib=rb-4.0.3&q=80&w=720',
  image4Src = 'https://plus.unsplash.com/premium_photo-1681489433354-f7fabdebd933?q=80&w=720&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  image10Alt = 'Hero Image',
  image4Alt = 'Hero Image',
  heading1,
  content1,
  image10Src = 'https://www.sotoncars.co.uk/wp-content/uploads/2024/04/img17.webp',
  image9Alt = 'Hero Image',
  image1Src = 'https://images.unsplash.com/photo-1635176776026-27d4c4a310f3?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMzc4MTE5MHw&ixlib=rb-4.0.3&q=80&w=720',
}) => {
  return (
    <div className="hero17-header78">
      <div className="hero17-column thq-section-padding thq-section-max-width">
        <div className="hero17-content1">
          {/* <h1> */}
          {heading1 ?? (
            <Fragment>
              <h1 className="hero17-text7 thq-heading-1">
                Book Your Birmingham Airport Taxi Online
              </h1>
            </Fragment>
          )}
          {/* </h1> */}
          {/* <p> */}
          {content1 ?? (
            <Fragment>
              <p className="hero17-text8 thq-body-large">
                Get a hassle-free taxi booking experience with our easy-to-use
                online platform. Simply enter your pickup and drop-off
                locations, choose your preferred car type, and make your
                reservation in just a few clicks.
              </p>
            </Fragment>
          )}
          {/* </p> */}
        </div>
        {/* <div className="hero17-actions">
          <button className="thq-button-filled hero17-button1">
            <span>
              {action1 ?? (
                <Fragment>
                  <span className="hero17-text6 thq-body-small">
                    Main action
                  </span>
                </Fragment>
              )}
            </span>
          </button>
          <button className="thq-button-outline hero17-button2">
            <span>
              {action2 ?? (
                <Fragment>
                  <span className="hero17-text5 thq-body-small">
                    Secondary action
                  </span>
                </Fragment>
              )}
            </span>
          </button>
        </div> */}
      </div>
      <div className="hero17-content2">
        <div className="hero17-row-container1 thq-animated-group-container-horizontal thq-mask-image-horizontal">
          <div className="thq-animated-group-horizontal">
            <img
              alt={image1Alt}
              src={image1Src}
              className="hero17-placeholder-image10 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={image2Alt}
              src={image2Src}
              className="hero17-placeholder-image11 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={image3Alt}
              src={image3Src}
              className="hero17-placeholder-image12 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={image4Alt}
              src={image4Src}
              className="hero17-placeholder-image13 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={image5Alt}
              src={image5Src}
              className="hero17-placeholder-image14 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={image6Alt}
              src={image6Src}
              className="hero17-placeholder-image15 thq-img-scale thq-img-ratio-1-1"
            />
          </div>
          <div className="thq-animated-group-horizontal">
            <img
              alt={image1Alt}
              src={image1Src}
              className="hero17-placeholder-image16 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={image2Alt}
              src={image2Src}
              className="hero17-placeholder-image17 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={image3Alt}
              src={image3Src}
              className="hero17-placeholder-image18 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={image4Alt}
              src={image4Src}
              className="hero17-placeholder-image19 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={image5Alt}
              src={image5Src}
              className="hero17-placeholder-image20 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt="Hero Image"
              src="https://images.unsplash.com/photo-1534312527009-56c7016453e6?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDIxfHxhYnN0cmFjdHxlbnwwfHx8fDE3MTA4NzA5MzB8MA&amp;ixlib=rb-4.0.3&amp;w=1500"
              className="hero17-placeholder-image21 thq-img-scale thq-img-ratio-1-1"
            />
          </div>
        </div>
        <div className="hero17-row-container2 thq-animated-group-container-horizontal thq-mask-image-horizontal">
          <div className="thq-animated-group-horizontal-reverse">
            <img
              alt={image7Alt}
              src={image7Src}
              className="hero17-placeholder-image22 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={image8Alt}
              src={image8Src}
              className="hero17-placeholder-image23 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={image9Alt}
              src={image9Src}
              className="hero17-placeholder-image24 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={image10Alt}
              src={image10Src}
              className="hero17-placeholder-image25 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={image11Alt}
              src={image11Src}
              className="hero17-placeholder-image26 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={image12Alt}
              src={image12Src}
              className="hero17-placeholder-image27 thq-img-scale thq-img-ratio-1-1"
            />
          </div>
          <div className="thq-animated-group-horizontal-reverse">
            <img
              alt={image7Alt}
              src={image7Src}
              className="hero17-placeholder-image28 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={image8Alt}
              src={image8Src}
              className="hero17-placeholder-image29 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={image9Alt}
              src={image9Src}
              className="hero17-placeholder-image30 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={image10Alt}
              src={image10Src}
              className="hero17-placeholder-image31 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt={image11Alt}
              src={image11Src}
              className="hero17-placeholder-image32 thq-img-scale thq-img-ratio-1-1"
            />
            <img
              alt="Hero Image"
              src="https://images.unsplash.com/photo-1568214379698-8aeb8c6c6ac8?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDEyfHxncmFmaWN8ZW58MHx8fHwxNzE1Nzk0OTk5fDA&amp;ixlib=rb-4.0.3&amp;w=1500"
              className="hero17-placeholder-image33 thq-img-scale thq-img-ratio-1-1"
            />
          </div>
        </div>
      </div>
      <div>
        <div className="hero17-container2">
          <Script
            html={`<style>
  @keyframes scroll-x {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100% - 16px));
    }
  }

  @keyframes scroll-y {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(calc(-100% - 16px));
    }
  }
</style>
`}
          ></Script>
        </div>
      </div>
    </div>
  )
}

Hero17.propTypes = {
  image3Src: PropTypes.string,
  image8Alt: PropTypes.string,
  image2Src: PropTypes.string,
  image6Alt: PropTypes.string,
  image11Src: PropTypes.string,
  image5Alt: PropTypes.string,
  image1Alt: PropTypes.string,
  image7Src: PropTypes.string,
  image7Alt: PropTypes.string,
  image12Alt: PropTypes.string,
  image2Alt: PropTypes.string,
  image6Src: PropTypes.string,
  image12Src: PropTypes.string,
  image3Alt: PropTypes.string,
  image9Src: PropTypes.string,
  image11Alt: PropTypes.string,
  action2: PropTypes.element,
  action1: PropTypes.element,
  image8Src: PropTypes.string,
  image5Src: PropTypes.string,
  image4Src: PropTypes.string,
  image10Alt: PropTypes.string,
  image4Alt: PropTypes.string,
  heading1: PropTypes.element,
  content1: PropTypes.element,
  image10Src: PropTypes.string,
  image9Alt: PropTypes.string,
  image1Src: PropTypes.string,
}

export default Hero17