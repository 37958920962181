import React, { useState, Fragment } from 'react'
// import easyBooking from "../assets/Images/easy-booking.jpg"
// import carOptions from "../assets/Images/car-options.avif"
// import security from "../assets/Images/security.svg"

import PropTypes from 'prop-types'

import './features24.css'

const Features24 = ({ 
  feature1ImgAlt = 'Image depicting easy booking process',
  feature3Description,
  feature3Title,
  feature3ImgSrc = "https://res.cloudinary.com/upwork-cloud/image/upload/c_scale,w_1000/v1709849705/catalog/1380085572175151104/dvz0nk7jjxskfo00jior.webp",
  feature1ImgSrc = "https://images.unsplash.com/photo-1563013544-824ae1b704d3?q=80&w=720&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  feature2Description,
  feature1Title,
  feature3ImgAlt = 'Image representing secure booking system',
  feature1Description,
  feature2ImgSrc = "https://images.netdirector.co.uk/gforces-auto/image/upload/q_auto,c_crop,f_auto,fl_lossy,x_0,y_0,w_4525,h_3392/w_1920,h_1440,c_fill/auto-client/78ebc2ccd3d7b2f305f38ee4ba7d8e2c/volvo_range_2018.jpg",
  feature2ImgAlt = 'Image showing different car options',
  feature2Title,
}) => {
  const [activeTab, setActiveTab] = useState(0)
  return (
    <div className="thq-section-padding p-4 container">
      <div className="features24-container2 thq-section-max-width">
        <div className="features24-image-container">
          {activeTab === 0 && (
            <img
              alt={feature1ImgAlt}
              src={feature1ImgSrc}
              className="features24-image1 thq-img-ratio-16-9"
            />
          )}
          {activeTab === 1 && (
            <img
              alt={feature2ImgAlt}
              src={feature2ImgSrc}
              className="features24-image2 thq-img-ratio-16-9"
            />
          )}
          {activeTab === 2 && (
            <img
              alt={feature3ImgAlt}
              src={feature3ImgSrc}
              className="features24-image3 thq-img-ratio-16-9"
            />
          )}
        </div>
        <div className="features24-tabs-menu">
          <div
            onClick={() => setActiveTab(0)}
            className="features24-tab-horizontal1"
          >
            <div className="features24-divider-container1">
              {activeTab === 0 && <div className="features24-container3"></div>}
            </div>
            <div className="features24-content1">
              {/* <h2> */}
              {feature1Title ?? (
                <Fragment>
                  <h2 className="features24-text4 thq-heading-2">
                    Easy Booking Process
                  </h2>
                </Fragment>
              )}
              {/* </h2> */}
              <span>
                {feature1Description ?? (
                  <Fragment>
                    <span className="features24-text5 thq-body-small">
                      Simple and user-friendly interface to book Birmingham
                      airport taxis in just a few clicks
                    </span>
                  </Fragment>
                )}
              </span>
            </div>
          </div>
          <div
            onClick={() => setActiveTab(1)}
            className="features24-tab-horizontal2"
          >
            <div className="features24-divider-container2">
              {activeTab === 1 && <div className="features24-container4"></div>}
            </div>
            <div className="features24-content2">
              {/* <h2> */}
              {feature2Title ?? (
                <Fragment>
                  <h2 className="features24-text6 thq-heading-2">
                    Multiple Car Options
                  </h2>
                </Fragment>
              )}
              {/* </h2> */}
              <span>
                {feature2Description ?? (
                  <Fragment>
                    <span className="features24-text3 thq-body-small">
                      Choose from a selection of 3 types of cars to suit your
                      needs and preferences
                    </span>
                  </Fragment>
                )}
              </span>
            </div>
          </div>
          <div
            onClick={() => setActiveTab(2)}
            className="features24-tab-horizontal3"
          >
            <div className="features24-divider-container3">
              {activeTab === 2 && <div className="features24-container5"></div>}
            </div>
            <div className="features24-content3">
              {/* <h2> */}
              {feature3Title ?? (
                <Fragment>
                  <h2 className="features24-text2 thq-heading-2">
                    Secure Booking System
                  </h2>
                </Fragment>
              )}
              {/* </h2> */}
              <span>
                {feature3Description ?? (
                  <Fragment>
                    <span className="features24-text1 thq-body-small">
                      Safe and secure online platform to provide your
                      information and make bookings with peace of mind
                    </span>
                  </Fragment>
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Features24.propTypes = {
  feature1ImgAlt: PropTypes.string,
  feature3Description: PropTypes.element,
  feature3Title: PropTypes.element,
  feature3ImgSrc: PropTypes.string,
  feature1ImgSrc: PropTypes.string,
  feature2Description: PropTypes.element,
  feature1Title: PropTypes.element,
  feature3ImgAlt: PropTypes.string,
  feature1Description: PropTypes.element,
  feature2ImgSrc: PropTypes.string,
  feature2ImgAlt: PropTypes.string,
  feature2Title: PropTypes.element,
}

export default Features24