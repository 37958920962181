import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faInfoCircle, faPhone } from '@fortawesome/free-solid-svg-icons';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import '@fortawesome/fontawesome-free/css/all.min.css';
import './confirmationPage.css'; // Import custom CSS
import Services from './Services';
import StepperComponent from './StepperComponent';

const ConfirmationPage = () => {
    return (
        <>
            <section className="quotation-body">
                <Services />
                <StepperComponent stepNo={3} />
                <div className="container confirmation-container">
                    <div className="confirmation-box my-3">
                        <div className="confirmation-icon">
                            <FontAwesomeIcon icon={faCheckCircle} />
                        </div>
                        <div className="confirmation-title">
                            Your Booking is Confirmed!
                        </div>
                        <div className="confirmation-text">
                            Thank you for choosing Birmingham Airport Taxi. A confirmation email has been sent to you. We look forward to providing you with a smooth and comfortable journey.
                        </div>
                        <a href='https://mail.google.com/mail/u/0/#inbox' className="btn-check-status" target='_blank'>Check Booking Email</a>
                        <div className="email-notice">
                            Please check your inbox (and spam folder) for the confirmation email.
                        </div>
                        <div className="additional-info">
                            <p><FontAwesomeIcon icon={faInfoCircle} /> If you have any questions, please contact our support team at <strong>info@birminghamairporttaxicab.co.uk</strong>.</p>
                            <p><FontAwesomeIcon icon={faPhone} /> You can also reach us by phone at <strong>+44 7440 445863</strong>.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ConfirmationPage;
