import React from "react";
import "./T&C.css"; // Import the CSS for custom styling

const TC = () => {
    return (
        <div className="container terms-container">
            <h1 className="terms-title">Terms and Conditions</h1>

            <section className="terms-section">
                <h2>1. Price for the Journey</h2>
                <p>
                    Our prices are fixed, and price calculation is based on the shortest route instead of the fastest route. The
                    ride booked on a fixed price must not deviate from the driver chosen route; any deviation requested by the
                    passenger will result in a change of fare decided by our company. We usually charge £2 per extra mile.
                </p>
            </section>

            <section className="terms-section">
                <h2>2. Luggage</h2>
                <p>
                    1 Medium Size Suitcase (Checked Luggage). Height + Width should not exceed 158cm or 62 inches and weigh 23kg
                    or 50lb. 1 hand luggage 56cm in height, 45cm in width, and 25cm in diameter, approximately 22 x 17.5 x 9.85
                    inches per person. Any luggage except the mentioned cannot be accommodated and is solely the responsibility of
                    the passenger.
                </p>
            </section>

            <section className="terms-section">
                <h2>3. Driver Right</h2>
                <p>
                    There are certain conditions where the driver has full right to refuse the passenger, where the driver feels
                    that the passenger is a threat to him/herself or the vehicle.
                </p>
            </section>

            <section className="terms-section">
                <h2>4. Change of Plans</h2>
                <p>
                    If there are any changes of plans, i.e., your flight is cancelled, delayed, or rescheduled, it is your
                    responsibility to inform the office so that we can change your booking details. Any deviation in pickup and
                    drop-off location will result in a change of fare depending on the locations of your choice.
                </p>
            </section>

            <section className="terms-section">
                <h2>5. Cancellations</h2>
                <p>
                    If a booking is <strong>cancelled less than 12 hours prior to the scheduled pickup time</strong>, there will be a cancellation
                    fee applied, which depends on the time we were informed.
                </p>
                <ul>
                    <li>
                        Cancellations made before the 12-hour mark before the scheduled pickup time are <strong>free of charge</strong>; however, a
                        bank fee will be applicable to all refundable bookings. The exact bank fee amount may vary based on the
                        total booking amount.
                    </li>
                    <li>
                        Cancellations made between <strong>12 to 6 hours</strong>  prior to the scheduled pickup time will incur a <strong>charge of 50% </strong> of the
                        total journey price.
                    </li>
                    <li>
                        Cancellations occurring within 6 hours of the pickup time will result in a <strong>charge equivalent to 100%</strong>  of the
                        journey price.
                    </li>
                    <li>Refunds will <strong>not be issued</strong>  if a passenger cannot be reached at the time of pickup.</li>
                    <li>Once a driver has been dispatched or is en route for the pickup, <strong>no refunds</strong>  will be provided.</li>
                </ul>
            </section>

            <section className="terms-section">
                <h2>5.1 Change in Pickup Time</h2>
                <p>
                    If a booking is made and a change in pickup time is required less than 12 hours prior to the scheduled pickup
                    time, it must be made over the phone, and we reserve the right to reject the change or cancel the booking; no
                    refunds for such bookings are applicable. However, we do our best to accommodate the changes in pickup time
                    near the scheduled pickup time, but all our drivers are assigned pre-booked connected jobs. It is not possible
                    to entertain all the requests for change in time at the last minute.
                </p>
            </section>

            <section className="terms-section">
                <h2>6. Communication</h2>
                <p>
                    It is very important to keep your phone switched on as it is the sole medium of communication for your pickup.
                    In such cases, where you are not contactable, you will still be charged for the journey fare. Always keep your
                    office number while you are travelling.
                </p>
            </section>

            <section className="terms-section">
                <h2>7. Locate the Driver</h2>
                <p>
                    When a driver is dispatched for an airport transfer service, we make every effort to ensure a smooth and
                    seamless pickup experience for our customers. However, in the event that a customer is unable to locate the
                    assigned driver at the designated pickup point, the following terms apply:
                </p>
                <ul>
                    <li>
                        <strong>7.1. Customer Responsibility:</strong> It is the responsibility of the customer to arrive at the
                        designated pickup point at the specified time. Customers are advised to be punctual and familiarize
                        themselves with the pickup location in advance.
                    </li>
                    <li>
                        <strong>7.2. Assistance Provided:</strong> We understand that locating the driver can sometimes be
                        challenging, especially in busy airport/ports environments. Therefore, we offer assistance to customers who
                        are having difficulty finding their assigned driver. Our customer support team is available to provide
                        guidance and assistance via phone or messaging.
                    </li>
                    <li>
                        <strong>7.3. Driver Communication:</strong> Our drivers are instructed to communicate with passengers upon
                        arrival at the pickup point. They will make reasonable efforts to identify themselves and make their presence
                        known to the customer. This may include displaying signage, contacting the customer directly, or using our
                        designated communication channels.
                    </li>
                    <li>
                        <strong>7.4. Limitation of Liability:</strong> While we strive to assist customers in locating their assigned
                        driver, we cannot guarantee that every customer will be able to do so successfully. Therefore, we hereby
                        disclaim any responsibility for situations where a customer is unable to locate the driver despite our
                        assistance efforts.
                    </li>
                    <li>
                        <strong>7.5. Good Faith Efforts:</strong> Despite the disclaimer of responsibility, we remain committed to
                        providing excellent customer service. We will make reasonable and good faith efforts to assist customers in
                        locating their assigned driver and ensuring a satisfactory resolution to any difficulties encountered.
                    </li>
                    <li>
                        <strong>7.6. Accuracy of Contact Information:</strong> By booking our airport transfer service, you agree to
                        abide by these terms regarding the locating of the driver. If you have any questions or require further
                        clarification, please don't hesitate to contact our customer support team. Customers are responsible for
                        providing accurate and up-to-date contact information at the time of booking. This includes but is not
                        limited to, contact numbers, email addresses, and any other relevant communication details. Failure to
                        provide accurate information may hinder our ability to assist in locating the driver in the event of any
                        difficulties.
                    </li>
                    <li>
                        <strong>7.7. Communication Channels:</strong> It is imperative that customers remain accessible and monitor
                        their provided communication channels (such as phone calls, text messages, or emails) during the designated
                        pickup window. Prompt responsiveness to communication attempts from our team or the assigned driver can help
                        expedite the pickup process and mitigate any potential delays.
                    </li>
                    <li>
                        <strong>7.8. Driver Arrival:</strong> If the driver is dispatched and arrives at the designated pickup
                        location, and despite efforts made by both the driver and the company to locate the passenger, the passenger
                        fails to connect with the driver and subsequently takes an alternative service, the customer will not be
                        eligible for a refund.
                    </li>
                    <li>
                        <strong>7.9. Refund Discretion:</strong> The decision to issue a refund or credit will be made at the
                        discretion of the company, taking into consideration the circumstances of the situation and the impact on
                        both the customer and the company. Refunds issued in eligible circumstances may be partial, taking into
                        account any costs incurred by the company, including but not limited to, driver travel expenses and time
                        spent.
                    </li>
                </ul>
            </section>

            <section className="terms-section">
                <h2>8. Fouling</h2>
                <p>
                    Any fouling of the Private Hire Vehicle by any passenger will result in a charge of a minimum of £30.00
                    dependent upon the severity of the fouling.
                </p>
            </section>

            <section className="terms-section">
                <h2>9. Requested Vehicle</h2>
                <p>
                    We try our level best to provide you with the vehicle you requested, but sometimes you may get a bigger
                    vehicle, depending on the situation and availability of the vehicles.
                </p>
            </section>

            <section className="terms-section">
                <h2>10. Flight Delays</h2>
                <p>
                    Our Company policy is to accommodate flight delays up to 1 hour while still we do our best to facilitate our
                    customers. We reserve the right to cancel the booking after the specified time as mentioned above. In case of
                    cancellation, we refund the full amount of the booking on the same card which was used to pay for the journey.
                    We take no responsibility for any kind of compensation, nor will we pay any additional charges you may incur
                    due to cancellation or arranging alternate transport.
                </p>
            </section>

            <section className="terms-section">
                <h2>11. Waiting Time</h2>
                <p>
                    We provide an additional 30 minutes free waiting time after the scheduled pickup time once a flight is landed
                    (Free waiting time is only applicable on pickups from the airport). After this, we charge £10 per 30 minutes.
                    In addition to this, if a customer is ready before its scheduled pickup time, we cannot guarantee an early
                    pickup; your pickup will be according to your scheduled pickup time you already have chosen while making the
                    booking. However, we will try to pick you up as early as possible if your already assigned driver is available.
                </p>
            </section>

            <section className="terms-section">
                <h2>12. Child Seat</h2>
                <p>
                    We provide a free Child Seat if requested by the customer. We try our best to provide the child seat, but we
                    cannot guarantee a child seat for suitability and availability for your journey.
                </p>
            </section>

            <footer className="terms-footer">
                <p>&copy; 2024 Birmingham Airport Taxi. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default TC;
