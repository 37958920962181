import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import './contactUs.css'; // You can include your CSS here
import { useFetchContext } from '../Context api/FetchContext';
import { FormSubmissionContext } from '../Context api/FormSubmissionContext';
import contactImage from '../assets//Images/contact.webp';


const Contact = () => {
  const { handleNotification } = useFetchContext();
  const { bookingAddress } = useContext(FormSubmissionContext);
  const [result, setResult] = useState("Send Message");

  const accessKey = process.env.REACT_APP_WEB3_KEY

  const onSubmit = async (event) => {
    event.preventDefault();
    const formMessage = document.getElementById("form_message");
    formMessage.style.color = "white";
    setResult("Please Wait....");
    formMessage.classList.remove('disabled');

    const formData = new FormData(event.target);


    formData.append("access_key", accessKey);

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
    });

    const data = await response.json();

    if (data.success) {
      formMessage.style.backgroundColor = "green";
      setResult("Your Message has been Sent Successfully!");
      formMessage.classList.add('disabled');
      handleNotification("success", "Success!", "Your message has been sent. We'll contact you soon.", 5000);

      event.target.reset();
    } else {
      console.log("Error", data);
      setResult(data.message);
      formMessage.style.color = "red";
    }
  };

  return (
    <div className="container contact-container mt-4">
      <div className="contact-header" style={{ backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5)), url(${contactImage})` }}>
        <div className="contact-header-content">
          <h1 className='display-5 fw-bold headingStyle'>Contact Us</h1>
        </div>
      </div>
      <div className="contact-info row">
        <a href="#" className="info-box col-md-4 col-sm-6">
          <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
          <h3>Address</h3>
          <p>{bookingAddress}</p>
        </a>

        <a href="tel:+447440445863" className="info-box col-md-4 col-sm-6">
          <FontAwesomeIcon icon={faPhoneAlt} className="icon" />
          <h3>Phone</h3>
          <p>+44 7440 445863</p>
        </a>

        <a href="mailto:info@birminghamairporttaxicab.co.uk" className="info-box col-md-4 col-sm-6">
          <FontAwesomeIcon icon={faEnvelope} className="icon" />
          <h3>Email</h3>
          <p>info@birminghamairporttaxicab.co.uk</p>
        </a>
      </div>

      <div className="contact-form-section">
        <h2>Contact Us</h2>
        <h3>Get In Touch</h3>
        <p>
          Hey! We love to hear about your requests or suggestions. If you have something in mind, don't be shy and reach out to us. Submit any of your messages, and our team will contact you ASAP. It has always been a pleasure to speak to you.
        </p>
        <form className="contact-form" onSubmit={onSubmit}>
          <input type="hidden" name="from_name" value="Someone submitted your Birmingham Airport Taxi website Contact from" />
          <input type="hidden" name="access_key" value={accessKey} />

          <div className="row">
            <div className="col-md-6">
              <input type="text" className="form-control" name="first_name" placeholder="First Name" required />
            </div>
            <div className="col-md-6">
              <input type="text" className="form-control" name="last_name" placeholder="Last Name" required />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <input type="text" className="form-control" name="phone" placeholder="Phone Number" required />
            </div>
            <div className="col-md-6">
              <input type="email" className="form-control" name="email" placeholder="Email" required />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <textarea className="form-control" name="message" placeholder="Message" rows="4" required></textarea>
            </div>
          </div>
          <button type="submit" className="btn btn-dark" id='form_message'>{result}</button>
        </form>
        {/* <span>{result}</span> */}
      </div>
    </div>
  );
};

export default Contact;