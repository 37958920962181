// suppressWarnings.js
const suppressedWarnings = [
    'Support for defaultProps will be removed from function components in a future major release. Use JavaScript default parameters instead.'
  ];
  
  const originalWarn = console.error;
  console.error = (message, ...args) => {
    if (typeof message === 'string' && !suppressedWarnings.some(warning => message.includes(warning))) {
      originalWarn(message, ...args);
    }
  };