import React, { useState, Fragment } from 'react'

import PropTypes from 'prop-types'
import { useFetchContext } from '../Context api/FetchContext';

import './navbar8.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
// import { Link } from 'react-router-dom';
const Navbar8 = ({
  action1,
  link2,
  logoAlt = 'Birmingham Airport Taxis Logo',
  logoSrc = new URL("../assets/Images/logo.png", import.meta.url).href,
  link1,
  link3,
  link4,
  link5,
  link6,
  action2
}) => {
  // const [link5AccordionOpen, setLink5AccordionOpen] = useState(false);
  // const [link5DropdownVisible, setLink5DropdownVisible] = useState(false);

  const { contactNo } = useFetchContext();

  // Add this state to manage the mobile menu visibility
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);

  // Add this function to toggle the mobile menu visibility
  const toggleMobileMenu = () => {
    setIsMobileMenuVisible(!isMobileMenuVisible);
  };

  // for mobile navbar preview classes: ↓ 
  // navbar8-mobile-menu teleport-show thq-show thq-translate-to-default
  return (
    <>
      <header className="navbar8-container1">
        <header data-thq="thq-navbar" className="navbar8-navbar-interactive py-3">
          <a href="/">
            <img
              alt={logoAlt}
              src={logoSrc}
              className="navbar8-image1"
            />
          </a>
          <div data-thq="thq-navbar-nav" className="navbar8-desktop-menu">
            <nav className="navbar8-links1">
              {/* <a href={link1Url}> */}
              {link1 ?? (
                <Fragment>
                  <span className="navbar8-text18 thq-link thq-body-small">
                    Home
                  </span>
                </Fragment>
              )}
              {/* </a> */}
              {/* <a href={link2Url}> */}
              {link6 ?? (
                <Fragment>
                  <span className="navbar8-text16 thq-link thq-body-small">
                    T&C
                  </span>
                </Fragment>
              )}
              {link2 ?? (
                <Fragment>
                  <span className="navbar8-text16 thq-link thq-body-small">
                    FAQ
                  </span>
                </Fragment>
              )}
              {/* </a> */}
              {link3 ?? (
                <Fragment>
                  <span className="navbar8-text24 thq-link thq-body-small">
                    About Us
                  </span>
                </Fragment>
              )}

              {link5 ?? (
                <Fragment>
                  <span className="navbar8-text24 thq-link thq-body-small">
                    Contact Us
                  </span>
                </Fragment>
              )}
              {/* {link4 ?? (
                <Fragment>
                  <span className="navbar8-text24 thq-link thq-body-small">
                  Airport Service
                  </span>
                </Fragment>
              )} */}

              {/* {link4 ?? (
              <Fragment>
                <span className="navbar8-text24 thq-link thq-body-small">
                  Airport Service
                </span>
              </Fragment>
            )} */}

            </nav>
            <div className="navbar8-buttons1 d-flex flex-wrap justify-content-end align-items-center">
              <a href="/" className="navbar8-action11 thq-button-animated thq-button-filled">
                <span className="thq-body-small">
                  {action1 ?? (
                    <Fragment>
                      <span className="navbar8-text15">Book Now</span>
                    </Fragment>
                  )}
                </span>
              </a>
              {/* <Link to="/manage-booking" className="navbar8-action21 thq-button-outline thq-button-animated">
                <span className="thq-body-small">
                  {action2 ?? (
                    <Fragment>
                      <span className="navbar8-text27">Manage Booking</span>
                    </Fragment>
                  )}
                </span>
              </Link> */}
            </div>
          </div>
          <div data-thq="thq-burger-menu" className="navbar8-burger-menu" onClick={toggleMobileMenu}>
            <svg viewBox="0 0 1024 1024" className="navbar8-icon14">
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>
          <div data-thq="thq-mobile-menu" className={`navbar8-mobile-menu  ${isMobileMenuVisible ? 'teleport-show thq-show thq-translate-to-default' : ''}`}>
            <div className="navbar8-nav">
              <div className="navbar8-top">
                <a href="/">
                  <img
                    alt={logoAlt}
                    src={logoSrc}
                    className="navbar8-logo"
                  />
                </a>
                <div data-thq="thq-close-menu" className="navbar8-close-menu" onClick={toggleMobileMenu}>
                  <svg viewBox="0 0 1024 1024" className="navbar8-icon16">
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <nav className="navbar8-links2">
                {/* <a href={link1Url}> */}
                {link1 ?? (
                  <Fragment>
                    <span className="navbar8-text18 thq-link thq-body-small">
                      Home
                    </span>
                  </Fragment>
                )}
                {/* </a> */}
                {/* <a href={link2Url}> */}
                {link6 ?? (
                  <Fragment>
                    <span className="navbar8-text16 thq-link thq-body-small">
                      FAQ
                    </span>
                  </Fragment>
                )}
                {link2 ?? (
                  <Fragment>
                    <span className="navbar8-text16 thq-link thq-body-small">
                      FAQ
                    </span>
                  </Fragment>
                )}
                {/* </a> */}
                {/* <a href={link3Url}> */}
                {link3 ?? (
                  <Fragment>
                    <span className="navbar8-text24 thq-link thq-body-small">
                      About Us
                    </span>
                  </Fragment>
                )}
                {/* <a href={link4Url}> */}
                {link4 ?? (
                  <Fragment>
                    <span className="navbar8-text24 thq-link thq-body-small">
                      Airport Service
                    </span>
                  </Fragment>
                )}
                {/* </a> */}

              </nav>
              <div className="navbar8-buttons2">
                <button className="thq-button-filled">
                  <span>
                    {action1 ?? (
                      <Fragment>
                        <span className="navbar8-text15">Book Now</span>
                      </Fragment>
                    )}
                  </span>
                </button>
                {/* <Link to="/manage-booking" className="thq-button-outline">
                  <span>
                    {action2 ?? (
                      <Fragment>
                        <span className="navbar8-text27">Manage Booking</span>
                      </Fragment>
                    )}
                  </span>
                </Link> */}
              </div>
            </div>
          </div>

        </header>
      </header>
      <a href={`https://wa.me/${contactNo}`} className="whatsapp-icon" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faWhatsapp} />
      </a>
    </>
  )
}


Navbar8.propTypes = {
  linkUrlPage4: PropTypes.string,
  link2Url: PropTypes.string,
  page4Description: PropTypes.element,
  page1ImageSrc: PropTypes.string,
  action1: PropTypes.element,
  page2ImageAlt: PropTypes.string,
  link2: PropTypes.element,
  linkUrlPage2: PropTypes.string,
  logoAlt: PropTypes.string,
  logoSrc: PropTypes.string,
  page3ImageAlt: PropTypes.string,
  linkUrlPage1: PropTypes.string,
  page1: PropTypes.element,
  link1: PropTypes.element,
  page4ImageSrc: PropTypes.string,
  linkUrlPage3: PropTypes.string,
  page4: PropTypes.element,
  page2: PropTypes.element,
  normalDropdownText: PropTypes.element,
  page4ImageAlt: PropTypes.string,
  page1Description: PropTypes.element,
  page2ImageSrc: PropTypes.string,
  page3ImageSrc: PropTypes.string,
  page2Description: PropTypes.element,
  link3: PropTypes.element,
  page3: PropTypes.element,
  link1Url: PropTypes.string,
  page3Description: PropTypes.element,
  page1ImageAlt: PropTypes.string,
  action2: PropTypes.element,
};

export default Navbar8
