import React, { createContext, useState, useEffect } from 'react';

export const FormSubmissionContext = createContext();

export const FormSubmissionProvider = ({ children }) => {

    // __________________________Content__________________________↓
    const bookingAddress = "Birmingham Airport, Birmingham, West Midlands, B26 3QJ, GBR";


    const [isFormSubmitted, setIsFormSubmitted] = useState(() => {
        // Check session storage for the initial state
        const savedState = sessionStorage.getItem('isFormSubmitted');
        return savedState ? savedState === 'true' : false; // Convert string to boolean, default to false
    });

    useEffect(() => {
        // Update session storage whenever isFormSubmitted changes
        sessionStorage.setItem('isFormSubmitted', isFormSubmitted);
    }, [isFormSubmitted]);


    return (
        <FormSubmissionContext.Provider value={{
            bookingAddress,

            isFormSubmitted,
            setIsFormSubmitted
        }}>
            {children}
        </FormSubmissionContext.Provider>
    );
};