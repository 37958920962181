import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './contact10.css'
import { Link } from 'react-router-dom'

const Contact10 = ({
  content1 = undefined,
  location2ImageSrc =
  'https://images.ctfassets.net/xj0skx6m69u2/4UHjYFxZwRLLKHK23yYRQS/ffe0c101ca208f8868cc13a187f5ddd8/Call-Center-Agents_blog-min.jpg?fm=jpg&w=648&h=426&fit=fill&f=Center&q=85',
  location1ImageSrc =
  'https://images.unsplash.com/photo-1480790846976-3aeb0c976d71?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMzc4MTE4NHw&ixlib=rb-4.0.3&q=80&w=1080',
  location1Description = undefined,
  location2ImageAlt = 'Phone icon',
  heading1 = undefined,
  location2Description = undefined,
  location1ImageAlt = 'Office building image',
  location1 = undefined,
  location2 = undefined,
}) => {

  return (
    <div className="contact10-container1 thq-section-padding">
      <div className="contact10-max-width thq-section-max-width">
        <div className="contact10-content1 thq-flex-row">
          <div className="contact10-content2">
            {/* <h2> */}
            {heading1 ?? (
              <Fragment>
                <h2 className="contact10-text18 thq-heading-2">Contact Us</h2>
              </Fragment>
            )}
            {/* </h2> */}
            {/* <p> */}
            {content1 ?? (
              <Fragment>
                <p className="contact10-text16 thq-body-large">
                  Need a ride? Reach out to us for seamless pickups and drop-offs at Birmingham Airport. We’re just a call away!
                </p>
              </Fragment>
            )}
            {/* </p> */}
          </div>
        </div>
        <div className="contact10-content3 thq-flex-row">
          <div className="contact10-container2">
            <img
              alt={location1ImageAlt}
              src={location1ImageSrc}
              className="contact10-image1 thq-img-ratio-16-9"
            />
            {/* <h3> */}
            {location1 ?? (
              <Fragment>
                <h3 className="contact10-text20 thq-heading-3">
                  Address: 123 Taxi Avenue, Birmingham, UK
                </h3>
              </Fragment>
            )}
            {/* </h3> */}
            {/* <p> */}
            {location1Description ?? (
              <Fragment>
                <p className="contact10-text17 thq-body-large">
                  Visit our office for any inquiries or assistance.
                </p>
              </Fragment>
            )}
            {/* </p> */}
            <div className="contact10-container3">
              <Link
                to="/contact"
                rel="noreferrer noopener"
                className="thq-button-flat thq-body-small"
              >
                Contact Us
              </Link>
            </div>
          </div>
          <div className="contact10-container4">
            <img
              alt={location2ImageAlt}
              src={location2ImageSrc}
              className="contact10-image2 thq-img-ratio-16-9"
            />
            {/* <h3> */}
            {location2 ?? (
              <Fragment>
                <h3 className="contact10-text21 thq-heading-3">
                  Phone: +44 744 044 5863
                </h3>
              </Fragment>
            )}
            {/* </h3> */}
            {/* <p> */}
            {location2Description ?? (
              <Fragment>
                <p className="contact10-text19 thq-body-large">
                  Call us for immediate support or booking questions.
                </p>
              </Fragment>
            )}
            {/* </p> */}
            <div className="contact10-container5">
              <a
                href="tel:+447440445863"
                rel="noreferrer noopener"
                className="thq-button-flat thq-body-small"
              >
                Call Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// Contact10.defaultProps = {
//   content1: undefined,
//   location2ImageSrc:
//     'https://images.unsplash.com/photo-1643789452276-1337729c2f74?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMzc4MTE4NHw&ixlib=rb-4.0.3&q=80&w=1080',
//   location1ImageSrc:
//     'https://images.unsplash.com/photo-1480790846976-3aeb0c976d71?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyMzc4MTE4NHw&ixlib=rb-4.0.3&q=80&w=1080',
//   location1Description: undefined,
//   location2ImageAlt: 'Phone icon',
//   heading1: undefined,
//   location2Description: undefined,
//   location1ImageAlt: 'Office building image',
//   location1: undefined,
//   location2: undefined,
// }

Contact10.propTypes = {
  content1: PropTypes.element,
  location2ImageSrc: PropTypes.string,
  location1ImageSrc: PropTypes.string,
  location1Description: PropTypes.element,
  location2ImageAlt: PropTypes.string,
  heading1: PropTypes.element,
  location2Description: PropTypes.element,
  location1ImageAlt: PropTypes.string,
  location1: PropTypes.element,
  location2: PropTypes.element,
}

export default Contact10
