import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
// import { FetchContext } from '../Context api/FetchContext'; // Import FetchContext
import './success.css'; // Import custom CSS file

function Success() {
    // const { isPaymentSuccessful } = useContext(FetchContext); // Get the state
    // const navigate = useNavigate();

    // useEffect(() => {
    //     if (!sessionStorage.getItem('orderData')) {
    //         navigate('/'); // Redirect to home if payment was not successful
    //     }
    // }, []);

    return (
        <Container className="d-flex justify-content-center align-items-center h-100 bg-success-light">
            <Row className="text-center justify-content-center">
                <Col md={8} lg={6}>
                    <div className="success-card p-5 rounded shadow-lg my-4">
                        <FontAwesomeIcon icon={faCheckCircle} size="3x" className="mb-4" />
                        <h1 className="display-4 mb-4">Payment Successful!</h1>
                        <p className="lead mb-4">Thank you for your booking. Your payment has been processed successfully.</p>
                        <Button variant="outline-light" href="/" className="btn-lg">Back to Home</Button>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default Success;